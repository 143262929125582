import { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { get, put } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
function EditRole() {
    const [formData, setFormData] = useState({
        role: ''
      });
      const [id, setId] = useState(null);
      const location = useLocation();
    
      useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        setId(id); // Set the ID value in the state variable
        fetchRoles(id); // Fetch organization data based on the ID
      }, [location.search]);
    
      const [response, setResponse] = useState(null);
      const [roles, setRoles] = useState([]);

      const fetchRoles = async (id) => {
        try {
            const response = await get('roles/' + id);
            setRoles(response.response);
            // Update form data with fetched organization data
            setFormData(response.response);          
        } catch (error) {
          console.error('Error fetching Roles:', error);
        }
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const slug = formData.role.replace(/\s+/g, '-').toLowerCase();
        // Add the slug to the form data
        const formDataWithSlug = {
            ...formData,
            slug: slug
        };
          const responseData = await put('roles/' +id , formDataWithSlug);
          setResponse(responseData);
          debugger
          if(responseData.statusCode === 200){
            toast.success("Updated Successfully", {
              onClose: () => {
                window.location.href='/roles';
              },
            });
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    

    return ( 
        <DashboardContent pageTitle="Edit Role">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Edit Role</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="EditRole" className="mt-5">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Form.Label>Role:</Form.Label>
                                    <Form.Control type="text" id="role" name="role" className="form-control" value={formData.role} onChange={handleChange} required />
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                   
                                </Col>
                                </Row>
                    <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Update Role</Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default EditRole;