import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function RolesAssigned() {
    const [rolesAssigned, setRolesAssigned] = useState([]);
    const [filteredRolesAssigned, setFilteredRolesAssigned] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rolesAssignedPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {
  
      fetchRolesAssigned();
    }, []);
  
    const fetchRolesAssigned = async () => {
      try {
        var organizationid = sessionStorage.getItem("organizationid");

        get('roleactivity')
          .then(response => {
            debugger
            const rolesAssignedData = response.response.filter(role => !role.organization);

            setRolesAssigned(rolesAssignedData);
            setFilteredRolesAssigned(rolesAssignedData);
          })
          .catch(error => console.error('Error fetching data:', error));          
    
      } catch (error) {
        console.error('Error fetching RolesAssigned:', error);
      }
    };
   
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterRolesAssigned(e.target.value);
    };
  
    const filterRolesAssigned = (query) => {
      const filteredAsss = rolesAssigned.filter(Ass =>
        // Ass.activity.toLowerCase().includes(query.toLowerCase()) ||
        Ass.activity_group && Ass.activity_group.name.toLowerCase().includes(query.toLowerCase()) ||
        Ass.role.role.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredRolesAssigned(filteredAsss);
      setCurrentPage(1);
    };
  
    const indexOfLastAss = currentPage * rolesAssignedPerPage;
    const indexOfFirstAss = indexOfLastAss - rolesAssignedPerPage;
    const currentAsss = filteredRolesAssigned.slice(indexOfFirstAss, indexOfLastAss);
    async function handleDelete(Id) {
      try {
        await del(`roleactivity/${Id}`);
        // Refresh RolesAssigned after deletion
        fetchRolesAssigned();
      } catch (error) {
        console.error('Error deleting RolesAssigned:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredRolesAssigned.length / rolesAssignedPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredRolesAssigned.length / rolesAssignedPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="RolesAssigned">
  <div>
        {/* <Row className='text-right my-5'>
          <Col md={{span:3, offset:9}}>
          <Button type="button" onClick={()=>window.location='/createIndustry'} className="fw-btn-fill btn-gradient-yellow">Create Industry</Button>
          </Col>
        </Row> */}

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
          <Col md={4} className="form-group my-5">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control"  />
          </Col>
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Role</th>
                <th>Groups</th>
                {/* <th>Activities</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedRolesAssigned">

              {/* Render RolesAssigned */}
              {currentAsss.map((ass, index) => (
                <tr key={index}>
                    <td>{ass.role.role}</td>
                   <td>{ass.activity_group.name }</td>
                   {/* <td>{ass.activities}</td>  */}
                   {/* <td>{ass.group ? ass.group.join(', ') : ''}</td>
                    <td>{ass.activity ? ass.activity.join(', ') : ''}</td> */}
                  <td><i onClick={()=>handleDelete(ass.id)} className="fa-regular fa-trash-can"></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredRolesAssigned.length / rolesAssignedPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredRolesAssigned.length / rolesAssignedPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default RolesAssigned;
