import React, { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import { get } from "./rest"; // Assuming get function is defined in rest for fetching questions
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

const QuizQuestionsModal = ({ show, onHide, quizId }) => {
  const [quizQuestions, setQuizQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await get(`assessment`);
        setQuizQuestions(response.response.filter(
          ass => ass.assessmentQuiz.id === quizId
        ));
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("Failed to fetch questions");
      }
    };

    if (show) {
      fetchQuestions();
    }
  }, [show, quizId]);

  const renderQuestion = (question) => {
    return (
      <Card key={question._id} className="p-3 mb-4">
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }}></div>
        {question.type !== 'fillintheblanks' && (
          <div className="d-flex">
            {question.answer1 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `a) ${DOMPurify.sanitize(question.answer1)}` }}></div>}
            {question.answer2 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `b) ${DOMPurify.sanitize(question.answer2)}` }}></div>}
            {question.answer3 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `c) ${DOMPurify.sanitize(question.answer3)}` }}></div>}
            {question.answer4 && <div className="d-flex me-2" dangerouslySetInnerHTML={{ __html: `d) ${DOMPurify.sanitize(question.answer4)}` }}></div>}
          </div>
        )}
      </Card>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Quiz Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {quizQuestions.map((question) => renderQuestion(question))}
      </Modal.Body>
    </Modal>
  );
};

export default QuizQuestionsModal;
