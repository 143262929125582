import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Students() {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [courseFilter, setCourseFilter] = useState('');
  const [courseCalendarTimeSlots, setCourseCalendarTimeSlots] = useState([]);

  useEffect(() => {
    fetchStudents();
    fetchTimeSlots();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await get('users');
      const studentsData = response.response.filter(emp => emp.role.slug === 'student');
      setStudents(studentsData);
      setFilteredStudents(studentsData);
    } catch (error) {
      console.error('Error fetching Students:', error);
    }
  };

  const fetchTimeSlots = async () => {
    try {
      const response = await get("coursecalendartimeslot");
      setCourseCalendarTimeSlots(response.response);
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };

  const fetchCourseDetails = async (courseId) => {
    try {
      const response = await get(`courses/${courseId}`);
      return response.courseName; // Assuming the API returns the course name
    } catch (error) {
      console.error("Error fetching course details:", error);
      return ''; // Return empty string if there's an error
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterStudents(e.target.value, courseFilter);
  };

  const handleCourseFilterChange = (e) => {
    setCourseFilter(e.target.value);
    filterStudents(searchQuery, e.target.value);
  };

  const filterStudents = (query, course) => {
    const filteredEmps = students.filter(emp => {
      const matchesSearchQuery =
        (emp.username && emp.username.toLowerCase().includes(query.toLowerCase())) ||
        (emp.firstname && emp.firstname.toLowerCase().includes(query.toLowerCase())) ||
        (emp.lastname && emp.lastname.toLowerCase().includes(query.toLowerCase())) ||
        (emp.email && emp.email.toLowerCase().includes(query.toLowerCase())) ||
        (emp.role && emp.role.role && emp.role.role.toLowerCase().includes(query.toLowerCase())) ||
        (emp.state && emp.state.toLowerCase().includes(query.toLowerCase())) ||
        (emp.city && emp.city.toLowerCase().includes(query.toLowerCase())) ||
        (emp.phoneNumber && emp.phoneNumber.toLowerCase().includes(query.toLowerCase()));
      
      const matchesCourseFilter = course ? courseCalendarTimeSlots.some(slot => 
        slot.course_calendar.course.course_title === course &&
        slot.subscribed_students.some(student => student.id === emp.id)
      ) : true;

      return matchesSearchQuery && matchesCourseFilter;
    });
    setFilteredStudents(filteredEmps);
    setCurrentPage(1);
  };

  const indexOfLastEmp = currentPage * studentsPerPage;
  const indexOfFirstEmp = indexOfLastEmp - studentsPerPage;
  const currentEmps = filteredStudents.slice(indexOfFirstEmp, indexOfLastEmp);

  async function handleDelete(Id) {
    try {
      await del(`users/${Id}`);
      fetchStudents();
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  }

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredStudents.length / studentsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <DashboardContent pageTitle="List of Students">
      <div>
        <Row className='text-right my-5'>
          <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={4} className="form-group">
            <Form.Control as="select" value={courseFilter} onChange={handleCourseFilterChange}>
              <option value="">All Courses</option>
              {courseCalendarTimeSlots.map((slot, index) => (
                <option key={index} value={slot.course_calendar.course.course_title}>
                  {slot.course_calendar.course.course_title} - ({slot.course_calendar.start_date} - {slot.course_calendar.end_date})
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col md={{ span: 3, offset: 1 }}>
            <Button type="button" onClick={() => window.location = '/createStudent'} className="fw-btn-fill btn-gradient-yellow">Create Student</Button>
          </Col>
        </Row>

        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                <th>Username</th>
                <th>First name</th>
                <th>Last name</th>
                <th>E-mail</th>
                <th>Phone</th>
                <th>Role</th>
                <th>State</th>
                <th>City</th>
                <th>Course</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedStudents">
              {currentEmps.map((emp, index) => (
                <tr key={index}>
                  <td>{emp.username}</td>
                  <td>{emp.firstname}</td>
                  <td>{emp.lastname}</td>
                  <td>{emp.email}</td>
                  <td>{emp.phoneNumber}</td>
                  <td>{emp.role.role}</td>
                  <td>{emp.state}</td>
                  <td>{emp.city}</td>
                  <td>
                    {courseCalendarTimeSlots
                      .filter(slot => slot.subscribed_students.some(student => student.id === emp.id))
                      .map((courseDetails, i, array) => (
                        <div key={i}>
                          {courseDetails.course_calendar.course.course_title}
                          {i < array.length - 1 && ','}
                        </div>
                      ))}
                  </td>
                  <td>
                    <i onClick={() => window.location = '/editstudent?id=' + emp.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                    <i onClick={() => window.location = '/coursecohort?id=' + emp.id} className="fa-solid fa-users-rectangle" style={{ paddingRight: '10px' }}></i>
                    <i onClick={() => handleDelete(emp.id)} className="fa-regular fa-trash-can"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <nav className='d-flex justify-content-end my-5 align-items-center'>
          <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredStudents.length / studentsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Students;
