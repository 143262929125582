import { Button, Col, Form, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import QuillEditor from "./QuillEditor";
import MultiSelectField from "./MultiSelectField";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { get, post } from "./rest";
import Select from "react-select";

function CreateIssue() {
  const organizationid = sessionStorage.getItem("organizationid");
  const authorid = sessionStorage.getItem("authorid");

  const [formData, setFormData] = useState({
    roleUserPairs: [{ role: null, users: [], optionsUsers: [] }],
    issue_name: '',
    issue_description: ''
  });
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [optionsCourses, setOptionsCourses] = useState([]);
  const [optionsInstructor, setOptionInstructor] = useState([]);
  const [optionsStudents, setOptionStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedInstructor, setSelectedInstructor] = useState();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchRolesOptions();
    fetchCourseOptions();
  }, []);

  const fetchRolesOptions = async () => {
    try {
      const response = await get('roles');
      var role = sessionStorage.getItem("roleslug");
      var organizationid = sessionStorage.getItem("organizationid");
      var filteredOptions = '';

      if (role === 'superadmin') {
        filteredOptions = response.response.filter(role => role.slug !== 'superadmin');
      } else if (role === 'organizationadmin') {
        filteredOptions = response.response.filter(role => role.slug === 'superadmin' || (role.organization && role.organization.id === organizationid && role.slug !== 'employee'));
      } else {
        filteredOptions = response.response.filter(role =>  role.slug === 'organizationadmin' || (role.organization && role.organization.id === organizationid) );
      }

      const options = filteredOptions.map(role => ({
        value: role.id,
        label: role.role
      }));
      setOptionsRoles(options);
    } catch (error) {
      console.error('Error fetching roles options:', error);
    }
  };
  const fetchCourseOptions = async () => {
    try {
      const response = await get('courses');
      var role = sessionStorage.getItem("roleslug");
      var filteredOptions = '';

      if (role === 'superadmin') {
        filteredOptions = response.response.filter(course => course.slug !== 'superadmin');
      } else if (role === 'organizationadmin') {
        filteredOptions = response.response.filter(course => course.organization && course.organization === organizationid);
      } else {
        filteredOptions = response.response.filter(course => course.organization && course.organization === organizationid);
      }
      debugger;
      const options = filteredOptions.map(course => ({
        value: course.id,
        label: course.course_title
      }));
      setOptionsCourses(options);
    } catch (error) {
      console.error('Error fetching roles options:', error);
    }
  };
  const fetchUsersByRole = async (roleId) => {
    try {
      const response = await get('users');
      const options = response.response
        .filter(user => user.role.id === roleId && user.organization && user.organization.id === organizationid && user.id !== authorid)
        .map(user => ({
          value: user.id,
          label: `${user.username} (${user.email})`,
        }));
      // Add a "Select All" option
      options.unshift({ value: "selectAll", label: "Select All" });
      return options;
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  const handleChangeCourse = async (selectedOptionCourses) => {
    setSelectedCourse(selectedOptionCourses);
    fetchInstructorOptions(selectedOptionCourses);
    fetchCourseStudentOptions(selectedOptionCourses);
  }
  const fetchInstructorOptions = async (selectedOptionCourses) =>{
    const response = await get('courses');
    const responseData = response.response.filter(course => course.id === selectedOptionCourses)
      .map(course => ({
        value: course.createdBy && course.createdBy.id,
        label: course.createdBy && course.createdBy.username + (course.createdBy.email)
      })).filter(instructor => instructor.value !== authorid); // Exclude the logged-in user
    setOptionInstructor(responseData);
  }
  const handleChangeInstructor = async (selectedOptionInstructor) => {
    debugger;
    setSelectedInstructor(selectedOptionInstructor);
  }
  const fetchCourseStudentOptions = async (selectedOptionCourses) =>{
    const response = await get('usercourseenrollment');
    const responseData = response.response.filter(course => course.course && course.course.id === selectedOptionCourses)
    .map(course => ({
      value: course.user.id,
      label: course.user.username + (course.user.email)
    })).filter(student => student.value !== authorid); // Exclude the logged-in user
  responseData.unshift({ value: "selectAll", label: "Select All" }); // Add "Select All" option
  setOptionStudents(responseData);
  }
  const handleChangeStudents = async (selectedOptionStudents) => {
    // Check if "Select All" is selected
    if (selectedOptionStudents && selectedOptionStudents.find(option => option.value === "selectAll")) {
      // Select all students
      setSelectedStudents(optionsStudents.filter(option => option.value !== "selectAll"));
    } else {
      setSelectedStudents(selectedOptionStudents);
    }
  };
  const handleChangeRoles = async (index, selectedOptionRoles) => {
    const optionsUsers = await fetchUsersByRole(selectedOptionRoles);
    const newRoleUserPairs = [...formData.roleUserPairs];
    newRoleUserPairs[index].role = selectedOptionRoles;
    newRoleUserPairs[index].users = []; // Reset users when role changes
    newRoleUserPairs[index].optionsUsers = optionsUsers;
    setFormData(prevState => ({
      ...prevState,
      roleUserPairs: newRoleUserPairs
    }));
  };

  const handleChangeUsers = (index, selectedOptionUsers) => {
    const newRoleUserPairs = [...formData.roleUserPairs];
    // Check if "Select All" is selected
    if (selectedOptionUsers && selectedOptionUsers.find(option => option.value === "selectAll")) {
      // Select all users
      newRoleUserPairs[index].users = newRoleUserPairs[index].optionsUsers.filter(option => option.value !== "selectAll");
    } else {
      newRoleUserPairs[index].users = selectedOptionUsers;
    }
    setFormData(prevState => ({
      ...prevState,
      roleUserPairs: newRoleUserPairs
    }));
  };

  const addRoleUserPair = () => {
    setFormData(prevState => ({
      ...prevState,
      roleUserPairs: [...prevState.roleUserPairs, { role: null, users: [], optionsUsers: [] }],
    }));
  };

  const removeRoleUserPair = (index) => {
    const newRoleUserPairs = formData.roleUserPairs.filter((_, i) => i !== index);
    setFormData(prevState => ({
      ...prevState,
      roleUserPairs: newRoleUserPairs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    // // Validate role and user fields
    // formData.roleUserPairs.forEach((pair, index) => {
    //   if (!pair.role) {
    //     validationErrors[`role-${index}`] = 'Role is required';
    //   }
    //   if (!pair.users || pair.users.length === 0) {
    //     validationErrors[`users-${index}`] = 'Users are required';
    //   }
    // });
    // Validate issue name and description
    if (!formData.issue_name) {
      validationErrors.issue_name = 'Issue name is required';
    }
    if (!formData.issue_description) {
      validationErrors.issue_description = 'Issue description is required';
    }
    // Ensure either instructor, students, or users from role-user pairs are selected
    const allUsers = [
      ...formData.roleUserPairs.reduce((acc, pair) => acc.concat(pair.users), []),
      ...(selectedInstructor ? [selectedInstructor] : []),
      ...(selectedStudents ? selectedStudents : []),
    ];

    if (allUsers.length === 0) {
      validationErrors.general = 'At least one user must be selected';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      
      const users = allUsers.map(user => user.value); // Extract user IDs

      // Prepare the metadata object
      const metadata = {
        description: formData.issue_description,
        createdon: new Date().toISOString(),
        sendername: sessionStorage.getItem("authorname"),
        // senderemail: sessionStorage.getItem("authoremail")
      };

      // Prepare the data to be sent to the API
      const submitData = {
        issue_name: formData.issue_name,
        metadata: [metadata],
        sender: sessionStorage.getItem("authorid"),
        users
      };
      debugger;
      const responseData = await post('messages', submitData);

      if (responseData.statusCode === 201) {
        for (let i = 0; i < allUsers.length; i++) {
          const user = allUsers[i];
          const userId = user;
            const notificationData = {
              createduser: submitData.sender,
              user: userId.value,
              organization: organizationid,
              link: `/viewchat?id=` + responseData.response.messageData.id,
              message: submitData.issue_name,
              type:"chat"
          };

          await post('notifications', notificationData);
        }
        toast.success("Sent Successfully", {
          onClose: () => {
            window.location.href = '/chats';            
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Send Message">
      <div className="card dashboard-card-ten">
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Label>Course:</Form.Label>
                  <Select
                    options={optionsCourses}
                    placeholder="Select Course"
                    onChange={(selectedOptionCourses) => handleChangeCourse(selectedOptionCourses.value)}                    
                  />                  
                  </Col>
                  </Row>
                  {selectedCourse && 
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Label>Instructor:</Form.Label>
                  <Select
                    options={optionsInstructor}
                    placeholder="Select Instructor"
                    onChange={(selectedOptionInstructor) => handleChangeInstructor(selectedOptionInstructor.value)}
                  />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Label>Students:</Form.Label>
                  <MultiSelectField
                    options={optionsStudents}
                    placeholder="Select Student"
                    onChange={(selectedOptionStudents) => handleChangeStudents(selectedOptionStudents)}
                    isMulti
                  />
                  </Col>
            </Row>
            }
            {formData.roleUserPairs.map((pair, index) => (
              <Row key={index} className="d-flex align-items-end">
                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Label>Role:</Form.Label>
                  <Select
                    options={optionsRoles}
                    placeholder="Select Role"
                    onChange={(selectedOptionRoles) => handleChangeRoles(index, selectedOptionRoles.value)}
                    // required
                    isInvalid={errors[`role-${index}`]}
                  />
                  {errors[`role-${index}`] && (
                    <Form.Control.Feedback type="invalid">
                      {errors[`role-${index}`]}
                    </Form.Control.Feedback>
                  )}
                </Col>
                {pair.role && (
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Users:</Form.Label>
                    <MultiSelectField
                      options={pair.optionsUsers}
                      value={pair.users}
                      placeholder="Select Users"
                      onChange={(selectedOptionUsers) => handleChangeUsers(index, selectedOptionUsers.value)}
                      isMulti
                      isInvalid={errors[`users-${index}`]}
                    />
                    {errors[`users-${index}`] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[`users-${index}`]}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                )}
                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  {formData.roleUserPairs.length > 1 && (
                    <Button
                      variant="danger"
                      className="mt-4"
                      onClick={() => removeRoleUserPair(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
            <Button variant="primary" onClick={addRoleUserPair} className="mt-3 mb-3 plain-btn">
              Add Role & Users
            </Button>
            <Row>
              <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                <Form.Label>Issue name:</Form.Label>
                <Form.Control
                  type="text"
                  id="issue_name"
                  name="issue_name"
                  className="form-control"
                  onChange={handleChange}
                  required
                  isInvalid={errors.issue_name}
                />
                {errors.issue_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.issue_name}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={8} xl={8} lg={8} xs={12} className="form-group">
                <Form.Label>Issue description:</Form.Label>
                <QuillEditor
                  name="issue_description"
                  value={formData.issue_description}
                  onChange={(content) => handleEditorChange("issue_description", content)}
                  required
                  isInvalid={errors.issue_description}
                />
                {errors.issue_description && (
                  <Form.Control.Feedback type="invalid">
                    {errors.issue_description}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Button
                      type="submit"
                      className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </DashboardContent>
  );
}

export default CreateIssue;
