import { Button, Col, Form, Row, Table } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { del, get } from "./rest";

function Results() {
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        fetchResults();
      }, []);
    
      const fetchResults = async () => {
        try {
          get('users')
            .then(response => {
              debugger
              var role = sessionStorage.getItem("roleslug");
              var organizationid = sessionStorage.getItem("organizationid");
              var orgAdmins ='';
              if (role !== 'superadmin') {
                orgAdmins = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.role.slug === 'organizationadmin' && admin.role.slug !== 'employee'  && admin.organization.id === organizationid);
              }
    
              setResults(orgAdmins);
              setFilteredResults(orgAdmins);
            })
            .catch(error => console.error('Error fetching data:', error));          
      
        } catch (error) {
          console.error('Error fetching Results:', error);
        }
      };
    const handleChange = (e) => {
        setSearchQuery(e.target.value);
        filterResults(e.target.value);
      };
      const filterResults = (query) => {
        const filteredOrgAdmins = results.filter(OrgAdmin => {
          // Check if all the necessary properties exist before filtering
          if (
            OrgAdmin.username &&
            OrgAdmin.firstname &&
            OrgAdmin.lastname &&
            OrgAdmin.email &&
            OrgAdmin.role &&
            OrgAdmin.role.role &&
            OrgAdmin.state &&
            OrgAdmin.city &&
            OrgAdmin.phone
          ) {
            return (
              OrgAdmin.username.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.firstname.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.lastname.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.email.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.role.role.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.state.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.city.toLowerCase().includes(query.toLowerCase()) ||
              OrgAdmin.phone.toLowerCase().includes(query.toLowerCase())
            );
          } else {
            // If any necessary property is missing, don't include this item in filtered results
            return false;
          }
        });
      
        setFilteredResults(filteredOrgAdmins);
        setCurrentPage(1);
      };
      const indexOfLastOrgAdmin = currentPage * resultsPerPage;
      const indexOfFirstOrgAdmin = indexOfLastOrgAdmin - resultsPerPage;
      const currentOrgAdmins = filteredResults.slice(indexOfFirstOrgAdmin, indexOfLastOrgAdmin);
      async function handleDelete(Id) {
        try {
          await del(`users/${Id}`);
          // Refresh industries after deletion
          fetchResults();
        } catch (error) {
          console.error('Error deleting industries:', error);
        }
      }
      const getPageNumbers = () => {
        const totalPages = Math.ceil(filteredResults.length / resultsPerPage);
        const pageNumbers = [];
        const maxVisiblePages = 5;
    
        if (totalPages <= maxVisiblePages) {
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          const startPage = Math.max(1, currentPage - 2);
          const endPage = Math.min(totalPages, currentPage + 2);
    
          if (startPage > 1) pageNumbers.push(1, '...');
          for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
          }
          if (endPage < totalPages) pageNumbers.push('...', totalPages);
        }
        return pageNumbers;
      };
    
      const handlePrevious = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNext = () => {
        if (currentPage < Math.ceil(filteredResults.length / resultsPerPage)) {
          setCurrentPage(currentPage + 1);
        }
      };
    return ( 
        <DashboardContent pageTitle="Results">
             <Row className=" gutters-8">
          <Col md={6} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>

        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                <th>Username</th>
                <th>First name</th>
                <th>Last name</th>
                <th>E-mail</th>
                <th>Phone</th>
                <th>Role</th>
                <th>State</th>
                <th>City</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedusers">
           
              {currentOrgAdmins.filter(OrgAdmin => OrgAdmin.role.slug !== 'employee').map((OrgAdmin, index) => (
                <tr key={index}>
                  <td>{OrgAdmin.username}</td>
                  <td>{OrgAdmin.firstname}</td>
                  <td>{OrgAdmin.lastname}</td>
                  <td>{OrgAdmin.email}</td>
                  <td>{OrgAdmin.phoneNumber}</td>
                  <td>{OrgAdmin.role.role}</td>
                  <td>{OrgAdmin.state}</td>
                  <td>{OrgAdmin.city}</td>
                  <td><i onClick={()=>window.location='/edituser?id='+ OrgAdmin.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(OrgAdmin.id)} className='fa fa-trash'></i></td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredResults.length / resultsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredResults.length / resultsPerPage)}
          >
            Next
          </Button>
        </nav>
        </DashboardContent>
     );
}

export default Results;