import React, { useEffect, useState } from 'react';
import DashboardContent from "./DashboardContent";
import { get } from './rest'
import { Table } from 'react-bootstrap';

function Enquiries() {

  const [data, setData] = useState(null);

  useEffect(() => {
    get('enquiries')
      .then(response => setData(response))
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  return (
    <DashboardContent pageTitle="Enquiries">
      <div className="table-responsive">
      {data && data.response && data.response.length > 0 && (
        <Table className="table display data-table" striped hover responsive>
          <thead>
            <tr>
              <th>Organization</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Area</th>
              <th>Subscription Type</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {data.response.map((item, index) => (
              <tr key={index}>
                <td>{item.adminOrganization}</td>
                <td>{item.adminemail}</td>
                <td>{item.adminphone}</td>
                <td>{item.adminaddress}</td>
                <td>{item.adminarea}</td>
                <td>{item.subscriptionType}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      </div>
    </DashboardContent>
  );
}

export default Enquiries;
