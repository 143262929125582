import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Industries() {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoriesPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {
  
      fetchCategories();
    }, []);
  
    const fetchCategories = async () => {
      try {
        get('categories')
          .then(response => {
            debugger
            var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var industrydata ='';
          if (role === 'superadmin') {
            industrydata = response.response.filter(category => role !== "employee" && role !== "organizationadmin" && !category.organization );
          } else if (role === 'organizationadmin') {
            industrydata = response.response.filter(category => role !== 'superadmin' && category.organization === organizationid);
          }
            setCategories(industrydata);
            setFilteredCategories(industrydata);
          })
          .catch(error => console.error('Error fetching data:', error));          
    
      } catch (error) {
        console.error('Error fetching Categories:', error);
      }
    };
  
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterCategories(e.target.value);
    };
  
    const filterCategories = (query) => {
      const filteredInds = categories.filter(Ind =>
        Ind.industry.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredCategories(filteredInds);
      setCurrentPage(1);
    };
  
    const indexOfLastInd = currentPage * categoriesPerPage;
    const indexOfFirstInd = indexOfLastInd - categoriesPerPage;
    const currentInds = filteredCategories.slice(indexOfFirstInd, indexOfLastInd);
    async function handleDelete(Id) {
      try {
        await del(`categories/${Id}`);
        // Refresh Categories after deletion
        fetchCategories();
      } catch (error) {
        console.error('Error deleting Categories:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredCategories.length / categoriesPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="Categories">
  <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control"  />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createIndustry'} className="fw-btn-fill btn-gradient-yellow">Create Category</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Thumbnail</th>
                <th>Category</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedCategories">

              {/* Render Categories */}
              {currentInds.map((Ind, index) => (
                <tr key={index}>
                   <td><img alt='thumbnail' src={Ind.thumbnail} width={100}></img></td>
                   <td>{Ind.industry}</td>
                  <td><i onClick={()=>window.location='/editindustry?id='+ Ind.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(Ind.id)} className='fa fa-trash'></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredCategories.length / categoriesPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredCategories.length / categoriesPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Industries;
