import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const CustomSelectField = ({ options, onChange, selected, placeholder, handleModuleSubmit }) => {
  const [newModuleTitle, setNewModuleTitle] = useState('');

  // Update filter based on input change
  const handleInputChange = (selectedOption) => {
    onChange(selectedOption.value);
  };

  // Filter options based on filter text
  // const filteredOptions = options.filter((option) =>
  //   option.label && option.label.toLowerCase().includes(filter.toLowerCase())
  // );

  // Handle module submission
  const handleLinkClick = async (e) => {
    e.preventDefault();
    if (newModuleTitle.trim() !== '') {
      handleModuleSubmit(e, newModuleTitle); // Pass filter text to handleModuleSubmit function
      setNewModuleTitle(''); // Clear filter after submission if needed
    } else if (selected) {
      // Handle selecting existing unit (if needed)
      onChange(selected); // Pass the selected value to the parent component
    }
  };
  const handleNewModuleTitleChange = (event) => {
    setNewModuleTitle(event.target.value);
  };
  return (
    <div>
      <Row className='d-flex align-items-center'>
      <Col md={6}>
        <Select
          options={options}
          value={options.find(option => option.value === selected)} // Find the selected option by value
          onChange={handleInputChange}
          placeholder={placeholder}
          isSearchable={true}
        />
      </Col>
      <Col>Or</Col>
      <Col md={5}>
        <Row className='d-flex align-items-center'>
          <Col md={10} className='p-0'>
            <Form.Control
              type="text"
              onChange={handleNewModuleTitleChange}
              placeholder="Enter new Module title"
            />
          </Col>
          <Col md={2} className='p-0'>
            <Button onClick={handleLinkClick} className='btn-fill-lg btn-gradient-yellow btn-hover-bluedark p-3'>
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
    </div>
  );
};

export default CustomSelectField;
