import { ToastContainer, toast } from "react-toastify";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { get, put } from "./rest";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function EditSubscriptionType() {
    const [formData, setFormData] = useState({
      subscriptiontype:"",
      price:""
      });
      const [id, setId] = useState(null);
      const location = useLocation();
    
      useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        setId(id); // Set the ID value in the state variable
        fetchSubscription(id); // Fetch Subscription data based on the ID
      }, [location.search]);
    
      const [subscription, setSubscription] = useState([]);
    
      const fetchSubscription = async (id) => {
        try {
          const response = await get('subscriptiontype/' + id);
          setSubscription(response.response);
          // Update form data with fetched Subscription data
          setFormData(response.response);
        } catch (error) {
          console.error('Error fetching Subscription:', error);
        }
      };
    
      const [response, setResponse] = useState(null);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const responseData = await put('subscriptiontype/' +id , formData);
          setResponse(responseData);
          debugger
          if(responseData.statusCode === 200){
            toast.success("Updated Successfully", {
              onClose: () => {
                window.location.href='/subscriptions';
              },
            });
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    
    return ( 
        <DashboardContent pageTitle="Edit Subscription Type">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Edit Subscription Type</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="editSubscriptionType" className="mt-5">
                            <Row>
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Form.Label>Subscription Type:</Form.Label>
                                    <Form.Control type="text" id="subscriptiontype" name="subscriptiontype" className="form-control" value={formData.subscriptiontype} onChange={handleChange} required />
                                </Col>
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Form.Label>Price:</Form.Label>
                                    <Form.Control type="text" id="price" name="price" className="form-control" value={formData.price} onChange={handleChange} required />
                                </Col>
                                {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group"> */}
                                    {/* <Form.Label>Image:</Form.Label>
                                    <div className="wrapper">
                                    <div className="box">
                                        <div className="js--image-preview"></div>
                                        <div className="upload-options">
                                        <Form.Label>
                                            <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" required />
                                        </Form.Label>
                                        </div>
                                    </div>
                                    </div> */}
                                {/* </Col> */}
                                <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                                    <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Update Subscription Type</Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default EditSubscriptionType;