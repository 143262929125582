import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { post } from "./rest";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const { email } = formData;
    try {
      // Check if the email exists in the user API
      const checkEmailResponse = await post(`/auth/check-email-exists`, { email: email });
      debugger
      // If the email exists
      if (checkEmailResponse.response !== false) {
        // Send the OTP
        const otpResponse = await post('/auth/send-email-otp', formData);

        if (otpResponse.statusCode === 201) {
          toast.success("OTP sent to your Mail", {
            onClose: () => {
              window.location.href = '/otp?email=' + email;
            },
          });
        } else {
          toast.error(otpResponse.response);
        }
      } else {
        toast.error('Email does not exist!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <div className="login-page-wrap">
        <div className="login-page-content">
          <div className="login-box">
            <div className="item-logo">
              <p className="menulogoonlog">
                Forgot Password
              </p>
            </div>
            <Form id="salogin" className="form-box" onSubmit={handleForgotPassword}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control className="form-control" onChange={handleChange}
                  type="text"
                  name="email"
                  placeholder="Enter email"
                />
              </Form.Group>


              <Button variant="primary" type="submit" className="login-btn">
                Get OTP
              </Button>
            </Form>
            <div className="sign-up">Back to <a href="/">Login now!</a></div>
            <ToastContainer />
          </div>

        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
