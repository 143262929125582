import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";
import QuillEditor from "./QuillEditor";

function EditAssessmentQuiz() {
  const [formData, setFormData] = useState({
    title: "",
    description: ""
  });

  const [id, setId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchAssessments(id);
  }, [location.search]);
 
  const fetchAssessments = async (id) => {
    try {
      const response = await get("assessmentquiz/" + id); // Define response only once
      debugger
      setFormData(response.response);
    } catch (error) {
      console.error("Error fetching Assessments:", error);
    }
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      title: value
    }));
  };

  const handleDescriptionChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      description: content
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const responseData = await put("assessmentquiz/" + id, formData);
      debugger
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href = "/assessments";
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <DashboardContent pageTitle="Edit Assessment">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit Assessment </h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form
                  onSubmit={handleSubmit}
                  id="editAssessment"
                  className="mt-5"
                >
                  <Row>
                
                <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                 <Form.Label> Title:</Form.Label>
                    <Form.Control
                    type="text"
                    placeholder="Quiz Title"
                    name="title"
                    value={formData.title}
                    onChange={handleTitleChange}
                    />
                    <Form.Label>Assessment Description:</Form.Label>

                    <QuillEditor
                    name="description"
                    value={formData.description}
                    onChange={(content) => handleDescriptionChange(content)}
                    />
                 </Col>
                    
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update Assessment
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditAssessmentQuiz;
