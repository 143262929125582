import React from "react";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Header from "./Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css"


function DashboardContent({ children, pageTitle }) {
  return (
    <div id="wrapper" className="wrapper bg-ash">
      <div className="dashboard-page-one">
        <div className="d-flex">
          <div>
            <Sidebar />
          </div>
          <div className="flex-grow-1">
            <div>
              <Header />
            </div>
            <div className="dashboard-content-one px-5" style={{ minHeight: "85vh", minWidth:"78vw" }}>
                  <div className="breadcrumbs-area">
                    <h3>{pageTitle}</h3>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>{pageTitle}</li>
                    </ul>
                </div>
                  {children}
            </div>
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
