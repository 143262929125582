import React from 'react';
import { Form } from 'react-bootstrap';

function SelectField({ options, onChange, selected, isSearchable, placeholder }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Form.Control as="select" onChange={handleChange} value={selected} isSearchable={isSearchable}>
      <option selected>{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
}

export default SelectField;
