import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function ListofRoles() {
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rolesPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {

    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      get('roles')
      .then(response => {
        debugger
        setRoles(response.response.filter(role => !role.organization));
        setFilteredRoles(response.response.filter(role => !role.organization));
      })
      .catch(error => console.error('Error fetching data:', error));  
      
    } catch (error) {
      console.error('Error fetching Roles:', error);
    }
  };
  async function handleDelete(orgId) {
    try {
      await del(`roles/${orgId}`);
      // Refresh Roles after deletion
      fetchRoles();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterRoles(e.target.value);
  };

  const filterRoles = (query) => {
    const filteredRoles = roles.filter(role =>
      role.role.toLowerCase().includes(query.toLowerCase()) 
    );
    setFilteredRoles(filteredRoles);
    setCurrentPage(1);
  };

  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = filteredRoles.slice(indexOfFirstRole, indexOfLastRole);
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredRoles.length / rolesPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredRoles.length / rolesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <DashboardContent pageTitle="List of Roles">
      <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createroles'} className="fw-btn-fill btn-gradient-yellow">Create Role</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={formData.area} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedRoles">
              {/* Render Roles */}
              {currentRoles.map((role, index) => (
                <tr key={index}>
                  <td>{role.role}{role.organization && - role.organization.adminOrganization}</td>
                  <td><i onClick={()=>window.location='/editRoles?id='+ role.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(role.id)} className='fa fa-trash'></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredRoles.length / rolesPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredRoles.length / rolesPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default ListofRoles;
