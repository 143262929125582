import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from 'react-bootstrap';
import SelectField from "./SelectField";
import { useState, useEffect } from "react";
import MultiSelectField from "./MultiSelectField";
import { ToastContainer, toast } from "react-toastify";
import { get, post } from "./rest";

function AssignRoles() {
    const [formData, setFormData] = useState({});
    const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
    const [optionsRoles, setOptionsRoles] = useState([]);
    const [optionsGroups, setOptionsGroups] = useState([]);
    const [activitiesList, setActivitiesList] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);

    useEffect(() => {
        fetchRolesOptions();
        fetchGroupOptions();
    }, []);

    const fetchRolesOptions = async () => {
        try {
            const response = await get('roles');
            const options = response.response.map(role => ({
                value: role.id,
                label: `${role.role} ${role.organization ? '- ' + role.organization.adminOrganization : ''}`
            }));
            setOptionsRoles(options);
        } catch (error) {
            console.error('Error fetching roles options:', error);
        }
    };

    const fetchGroupOptions = async () => {
        try {
            const response = await get('activitygroups');
            setActivitiesList(response.response);
            const groupsOptions = response.response.map(group => ({
                value: group.id,
                label: group.name
            }));
            setOptionsGroups(groupsOptions);
        } catch (error) {
            console.error('Error fetching activities options:', error);
        }
    };

    const handleGroupChange = (selectedGroupOption, index) => {
        const updatedGroups = [...assignedGroups];
        updatedGroups[index].group = selectedGroupOption;
        // Find the activities for the selected group
        const groupActivities = activitiesList.find(group => group.id === selectedGroupOption)?.activity.map(activity => ({
            value: activity.id,
            label: activity.name
        }));
        updatedGroups[index].activities = groupActivities || [];
        setAssignedGroups(updatedGroups);
    };

    const handleChange = (selectedOption, index) => {
        const updatedGroups = [...assignedGroups];
        updatedGroups[index].selectedActivities = selectedOption;
        setAssignedGroups(updatedGroups);
    };

    const handleAddGroup = () => {
        setAssignedGroups(prevGroups => [
            ...prevGroups,
            { group: null, activities: [], selectedActivities: [] }
        ]);
    };

    // const handleRemoveGroup = (index) => {
    //     debugger
    //     setAssignedGroups(prevGroups => prevGroups.filter((group, i) => i !== index));
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            debugger
            const dataToSend = assignedGroups.map(group => ({
                role: selectedOptionRoles,
                activity_group: group.group,
                activity: group.selectedActivities.map(activity => activity.value)
            }));
            debugger
            const responseData = await post('/roleactivity/create-many', dataToSend);
    
            if (responseData.statusCode === 201) {
                toast.success("Created Successfully", {
                    onClose: () => {
                        window.location.href = '/rolesassigned';
                    },
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    

    return (
        <DashboardContent pageTitle="Assign Roles">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Assign Roles</h3>
                                </div>
                            </div>
                            <div className="student-info">
                                <Form onSubmit={handleSubmit} id="assignRoles" className="mt-5">
                                    <Row>
                                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                            <Form.Label>Roles:</Form.Label>
                                            <SelectField options={optionsRoles} onChange={setSelectedOptionRoles} />
                                        </Col>
                                    </Row>
                                    {assignedGroups.map((assignedGroup, index) => (
                                        <Row key={index}>
                                            <Col md={5} xl={5} lg={5} xs={12} className="form-group">
                                                <Form.Label>Group:</Form.Label>
                                                <SelectField
                                                    options={optionsGroups}
                                                    onChange={(selectedGroupOption) => handleGroupChange(selectedGroupOption, index)}
                                                />
                                            </Col>
                                            <Col md={5} xl={5} lg={5} xs={12} className="form-group">
                                                <Form.Label>Activities:</Form.Label>
                                                <MultiSelectField
                                                    options={assignedGroup.activities}
                                                    onChange={(selectedOption) => handleChange(selectedOption, index)}
                                                />
                                            </Col>
                                            {/* <Col md={2} className="form-group d-flex align-items-end">
                                                <Button
                                                    className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                                                    onClick={() => handleRemoveGroup(index)}
                                                >
                                                    Remove
                                                </Button>
                                            </Col> */}
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col md={12} className="form-group">
                                            <Button
                                                type="button"
                                                className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                                                onClick={handleAddGroup}
                                            >
                                                Add Group
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="form-group">
                                            <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Assign Roles</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </DashboardContent>
    );
}

export default AssignRoles;
