import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";

function SetNewPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [formData, setFormData] = useState({
        email: '',
        newPassword: ''
    });
    const [email, setEmail] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('email');
        setEmail(id); 
        setFormData(prevState => ({
            ...prevState,
            email: id
        }));
    }, [location.search]);

    function validatePassword(value) {
        if (value.length < 8 || !value.match(/[0-9]/) || !value.match(/[a-zA-Z]/)) {
            setPasswordError("Password must be at least 8 characters long and contain at least one letter and one number.");
        } else {
            setPasswordError("");
        }
        // Update the form data
        setFormData(prevState => ({
            ...prevState,
            newPassword: value
        }));
    }

    function validateConfirmPassword(value) {
        if (value !== password) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    }

    async function handleSetNewPassword(e) {
        e.preventDefault();

        validatePassword(password);
        validateConfirmPassword(confirmPassword);

        // If both passwords are valid, proceed with password change logic
        if (!passwordError && !confirmPasswordError) {
            try {
                debugger
                const responseData = await post('/auth/set-new-password', {
                    email: email,
                    newPassword: password
                });
                debugger
                if(responseData.statusCode === 201) {
                    toast.success("Password Changed Successfully", {
                        onClose: () => {
                          window.location.href='/';
                        },
                      });
                } else {
                    toast.error(responseData.response);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    }

    return (
        <>
            <div className="login-page-wrap">
                <div className="login-page-content">
                    <div className="login-box">
                        <div className="item-logo">
                            <p className="menulogoonlog">
                                Change Password
                            </p>
                        </div>
                        <Form id="salogin" className="form-box" onSubmit={handleSetNewPassword}>
                            <Form.Group className="form-group" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    className="form-control" 
                                    type="password"
                                    placeholder="Enter password"
                                    name="newPassword"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); validatePassword(e.target.value); }}
                                />
                                {passwordError && <p className="error-message">{passwordError}</p>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="password"
                                    placeholder="Confirm password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(e.target.value); }}
                                />
                                {confirmPasswordError && <p className="error-message">{confirmPasswordError}</p>}
                            </Form.Group>
                            <Button variant="primary" type="submit" className="login-btn" disabled={passwordError || confirmPasswordError}>
                                Change Password
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default SetNewPassword;
