import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
// import SelectField from './SelectField';
import { ToastContainer, toast } from 'react-toastify';
import { get, post } from './rest';
import SelectField from './SelectField';

function CreateStudent() {
  const [formData, setFormData] = useState({
  });
  // const [optionsRoles, setOptionsRoles] = useState([]);
  const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [emailExists, setEmailExists] = useState(false);
  const [optionsOrganization, setOptionsOrganization] = useState([]);
  const [showOrganizationField, setShowOrganizationField] = useState(true); // State to control organization field visibility
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  var role = sessionStorage.getItem("roleslug");
  var organizationid = sessionStorage.getItem("organizationid");
  useEffect(() => {
    fetchRolesOptions();
    if( role === 'superadmin'){
      fetchOrganizationOptions();
    } else{
      setShowOrganizationField(false);
      setFormData(prevState => ({
        ...prevState,
        organization: organizationid 
      }));     
    }
}, [organizationid, role]);
const fetchRolesOptions = async () => {
  try {
      const response = await get('roles');
      const roleStudent = response.response.find(role => role.slug === 'student');
      if (roleStudent) {
          setSelectedOptionRoles(roleStudent.id);
          setFormData(prevState => ({
                    ...prevState,
                    role: roleStudent.id 
                }));
      } else {
          console.error('Role with slug "Student" not found.');
      }
      // const options = response.response.map(role => ({
      //     value: role.id,
      //     label: role.role
      // }));
      // setOptionsRoles(options);
  } catch (error) {
      console.error('Error fetching roles options:', error);
  }
};
const fetchOrganizationOptions = async () => {
  try {
      const response = await get('organization');
      const options = response.response.map(org => ({
          value: org._id,
          label: org.adminOrganization
      }));
      setOptionsOrganization(options);
  } catch (error) {
      console.error('Error fetching organization options:', error);
  }
};
const handleChangeOrganization = (selectedOrganization) => {
  setSelectedOrganization(selectedOrganization);
  setFormData(prevState => ({
    ...prevState,
    organization: selectedOrganization 
}));
};
const handleChange = (e) => {
  const { name, value } = e.target;
  let newValue = value;

  if (name === 'phoneNumber') {
    // Remove any non-numeric characters
    newValue = value.replace(/\D/g, '');

    // Restrict to 10 digits
    newValue = newValue.slice(0, 10);

    // Check if newValue is different from original value (means non-numeric characters were entered)
    if (newValue !== value) {
      setPhoneError('Phone number should contain only numbers.');
    } else if (newValue.length !== 10) {
      // Validate if the phone number has exactly 10 digits
      setPhoneError('Phone number must be 10 digits long.');
    } else {
      setPhoneError('');
    }
  }

  setFormData(prevState => ({
    ...prevState,
    [name]: newValue
  }));
};
const handleEmailChange = async (e) => {
  const { name, value } = e.target;
  try {
    // Make an API call to check if the email already exists
    const checkEmailResponse = await post(`/auth/check-email-exists`, { email: value });
    debugger
    setEmailExists(checkEmailResponse.response);
    // If the email already exists in the database
    if (checkEmailResponse.response === true) {
      toast.error('Email already exists!');
    } else {
      // If the email is unique, update the form data
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  } catch (error) {
    console.error('Error checking email:', error);
  }
};
//   const handleChangeRoles = (selectedOptionRoles) => {
//     setSelectedOptionRoles(selectedOptionRoles);
//     setFormData(prevState => ({
//         ...prevState,
//         role: selectedOptionRoles 
//     }));
// };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = await post('users', formData);

      debugger
      if(responseData.statusCode === 201){
        toast.success("Created Successfully", {
          onClose: () => {
            window.location.href='/Students';
          },
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  return (
    <DashboardContent pageTitle="Create Student">
    <Row>
    <Col md={12}>
        <div className="card dashboard-card-ten">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">
                <h3> Create Student </h3>
              </div>
            </div>
            <div className="student-info">
              <Form onSubmit={handleSubmit} id="createemp" className="mt-5">
              <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label >Student Username:</Form.Label>
                    <Form.Control type="text" id="username" name="username" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" id="password" name="password" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>First name:</Form.Label>
                    <Form.Control type="text" id="firstname" name="firstname" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label >Last name:</Form.Label>
                    <Form.Control type="text" id="lastname" name="lastname" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" id="email" name="email" className="form-control" onChange={handleEmailChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="tel" id="phoneNumber" name="phoneNumber" className={`form-control ${phoneError ? 'is-invalid' : ''}`} onChange={handleChange} required />
                        {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                    </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>City:</Form.Label>
                    <Form.Control type="text" id="city" name="city" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>State:</Form.Label>
                    <Form.Control type="text" id="state" name="state" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Role:</Form.Label>
                    <SelectField options={optionsRoles} onChange={handleChangeRoles} />     

                  </Col> */}
                  {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  </Col> */}
                  {showOrganizationField && (
                      <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                        <Form.Label>Organization:</Form.Label>
                        <SelectField options={optionsOrganization} onChange={handleChangeOrganization} />
                      </Col>
                    )}  
                    </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" disabled={emailExists}>Create User</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <ToastContainer />
    </DashboardContent>
  );
}

export default CreateStudent;
