import React, { useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

const UnitSelectField = ({ options, onChange, selected, placeholder, handleUnitSubmit }) => {
  const [newUnitTitle, setNewUnitTitle] = useState('');

  const handleChangeUnit = (selectedOption) => {
    onChange(selectedOption.value); // Pass the selected value to the parent component
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();
    if (newUnitTitle.trim() !== '') {
      handleUnitSubmit(e, newUnitTitle);
      setNewUnitTitle('');
      onChange(null); 
    } else if (selected) {
      // Handle selecting existing unit (if needed)
      onChange(selected); // Pass the selected value to the parent component
    }
  };

  const handleNewUnitTitleChange = (event) => {
    setNewUnitTitle(event.target.value);
  };

  return (
    <Row className='d-flex align-items-center'>
      <Col md={6}>
      
        <Select
          options={options}
          value={options.find(option => option.value === selected || null)} // Find the selected option by value
          onChange={handleChangeUnit}
          placeholder={placeholder}
          isSearchable={true}
        />
      </Col>
      <Col>Or</Col>
      <Col md={5}>
        <Row className='d-flex align-items-center'>
          <Col md={10} className='p-0'>
            <Form.Control
              type="text"
              value={newUnitTitle}
              onChange={handleNewUnitTitleChange}
              placeholder="Enter new unit title"
            />
          </Col>
          <Col md={2} className='p-0'>
            <Button onClick={handleLinkClick} className='btn-fill-lg btn-gradient-yellow btn-hover-bluedark p-3'>
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UnitSelectField;
