import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import MultiSelectField from './MultiSelectField';
import SelectField from './SelectField';

function CreateSubscriptionType() {
    const [formData, setFormData] = useState({

    });
    const [response, setResponse] = useState(null);
    const [optionsGroups, setOptionsGroups] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    useEffect(() => {
      
      fetchGroupOptions();
  }, []);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
    const fetchGroupOptions = async () => {
      try {
          const response = await get('activitygroups');
          const groupsOptions = response.response.map(group => ({
              value: group.id,
              label: group.name
          }));
          setOptionsGroups(groupsOptions);
      } catch (error) {
          console.error('Error fetching activities options:', error);
      }
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {

      debugger
        const responseData = await post('subscriptiontype', formData);
        setResponse(responseData);
        // toast.success(responseData.response);
        debugger
        if(responseData.statusCode === 201){
          toast.success("Created Successfully", {
            onClose: () => {
              window.location.href='/subscriptions';
            },
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  
  //   const handleGroupChange = (selectedGroupOption) => {     
  //     setAssignedGroups(selectedGroupOption);
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       activityGroups: selectedGroupOption.value,
  //     }));
  // };

    return ( 
        <DashboardContent pageTitle="Create Subscription Type">
            <Row>
                <Col md={12}>
                    <div className="card dashboard-card-ten">
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Create Subscription Type</h3>
                                </div>
                            </div>
                            <div className="student-info">
                            <Form onSubmit={handleSubmit} id="createSubscriptionType" className="mt-5">
                            <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Form.Label>Subscription Type:</Form.Label>
                                    <Form.Control type="text" id="subscriptiontype" name="subscriptiontype" className="form-control" onChange={handleChange} required />
                                </Col>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Form.Label>Price:</Form.Label>
                                    <Form.Control type="text" id="price" name="price" className="form-control" onChange={handleChange} required />
                                </Col>
                                </Row>
                                <Row>
                                <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                                    <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >Create Subscription Type</Button>
                                </Col>
                                </Row>
                            </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        <ToastContainer />
    </DashboardContent>
    );
}

export default CreateSubscriptionType;