import { Button, Col, Form, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { post } from "./rest";
import QuillEditor from "./QuillEditor";

function WebsiteSetUp() {
    const [formData, setFormData] = useState({

      });
      const [phoneError, setPhoneError] = useState('');
      const [uploading, setUploading] = useState(false);
      const [bannerURL, setBannerURL] = useState('');

      const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
      
        if (name === 'phoneNumber') {
          // Remove any non-numeric characters
          newValue = value.replace(/\D/g, '');
      
          // Restrict to 10 digits
          newValue = newValue.slice(0, 10);
      
          // Check if newValue is different from original value (means non-numeric characters were entered)
          if (newValue !== value) {
            setPhoneError('Phone number should contain only numbers.');
          } else if (newValue.length !== 10) {
            // Validate if the phone number has exactly 10 digits
            setPhoneError('Phone number must be 10 digits long.');
          } else {
            setPhoneError('');
          }
        }
      
        setFormData(prevState => ({
          ...prevState,
          [name]: newValue
        }));
      };
      const handleEditorChange = (name, content) => {
        setFormData((prevState) => ({
          ...prevState,
          [name]: content,
        }));
      };
      const handleBannerChange = async (event) => {
        setUploading(true);
          const file = event.target.files[0];
          const formData = new FormData();
          formData.append('files', file);
          formData.append('path', 'prod/default/website/banner'); 
        
          try {
              debugger
              const response = await fetch('http://api.campusplus360.in/upload', {
                  method: 'POST',
                  body: formData,
              }); 
              if (response.status === 201) { // Checking for response.ok instead of statusCode === 200
                  const data = await response.json(); // Assuming the response is in JSON format
                  debugger
                  if (data.response.length > 0) {
                    const firstObject = data.response[0]; // Accessing the first object in the array
                    const url = firstObject.url; // Assuming the URL key is 'url' in the object
                    setBannerURL(url); // Set the Banner URL
                    toast.success('Banner Uploaded successfully');
                  } 
              } else {
                  toast.error('Failed to upload image');
              }
          } catch (error) {
              console.error('Error uploading image:', error);
              toast.error('Failed to upload image');
          } finally {
            setUploading(false); // Set uploading to false when upload completes
          }
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const roleid = sessionStorage.getItem("roleid");
        const roleslug = sessionStorage.getItem("roleslug");
        const organizationid = sessionStorage.getItem("organizationid");

        if (roleslug === 'superadmin'){

        } else {

          formData.organization = organizationid;
        }
          const formDataWithAnswer = {
            ...formData,

            role:roleid
          };
          debugger
          const responseData = await post("OrganizationWebsite", formDataWithAnswer);
          if (responseData.statusCode === 201) {
            toast.success("Submitted Successfully", {
              onClose: () => {
                window.location.href = "/websitesetup";
              },
            });
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      };

    return ( 
        <DashboardContent pageTitle="Website Setup">
            <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Website Set Up</h3>
                </div>
              </div>
              <div className="student-info hidecheckox">
                <Form onSubmit={handleSubmit} id="websitesetup" className="mt-5">
                  <Row>
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Title 1</Form.Label>
                        <Form.Control
                        className="form-control"
                        type="text"
                        placeholder="Enter Title 1"
                        name="title"
                        onChange={handleChange}
                        />
                    </Form.Group>
                    </Col>
                   
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Title 2</Form.Label>
                        <Form.Control
                        className="form-control"
                        type="text"
                        placeholder="Enter Title 2"
                        name="title"
                        onChange={handleChange}
                        />
                    </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="paragraph1">
                        <Form.Label>Paragraph 1</Form.Label>
                        <QuillEditor
                          name="paragraph1"
                          value={formData.paragraph1}
                          onChange={(content) =>
                            handleEditorChange("paragraph1", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="paragraph2">
                        <Form.Label>Paragraph 2</Form.Label>
                        <QuillEditor
                          name="paragraph2"
                          value={formData.paragraph2}
                          onChange={(content) =>
                            handleEditorChange("paragraph2", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" id="email" name="email" className="form-control" onChange={handleChange} required />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="tel" id="phoneNumber" name="phoneNumber" className={`form-control ${phoneError ? 'is-invalid' : ''}`} onChange={handleChange} required />
                        {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Facebook:</Form.Label>
                        <Form.Control type="url" id="facebook" name="facebook" className="form-control" onChange={handleChange} />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Twitter:</Form.Label>
                        <Form.Control type="url" id="twitter" name="twitter" className="form-control" onChange={handleChange} />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Youtube:</Form.Label>
                        <Form.Control type="url" id="youtube" name="youtube" className="form-control" onChange={handleChange} />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        <Form.Label>Instagram:</Form.Label>
                        <Form.Control type="url" id="instagram" name="instagram" className="form-control" onChange={handleChange} />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Banner:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                            <Form.Label>
                              <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" onChange={handleBannerChange} required />
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button type="submit" className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
                         Website Set Up
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
        </DashboardContent>
     );
}

export default WebsiteSetUp;