import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { get,del } from './rest'


function ListofOrganizations() {
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [organizationsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {

    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
        get('organization')
          .then(response => {
            debugger
            setOrganizations(response.response);
            setFilteredOrganizations(response.response);
          })
          .catch(error => console.error('Error fetching data:', error));          
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  async function handleDelete(orgId) {
    try {
      await del(`organization/${orgId}`);
      // Refresh organizations after deletion
      fetchOrganizations();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterOrganizations(e.target.value);
  };

  const filterOrganizations = (query) => {
    const filteredOrgs = organizations.filter(org =>
      org.adminOrganization.toLowerCase().includes(query.toLowerCase()) ||
      org.adminemail.toLowerCase().includes(query.toLowerCase()) ||
      org.adminphone.toLowerCase().includes(query.toLowerCase()) ||
      org.adminarea.toLowerCase().includes(query.toLowerCase()) ||
      org.adminaddress.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOrganizations(filteredOrgs);
    setCurrentPage(1);
  };

  const indexOfLastOrg = currentPage * organizationsPerPage;
  const indexOfFirstOrg = indexOfLastOrg - organizationsPerPage;
  const currentOrgs = filteredOrganizations.slice(indexOfFirstOrg, indexOfLastOrg);
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredOrganizations.length / organizationsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredOrganizations.length / organizationsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <DashboardContent pageTitle="List of Organizations">
      <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createOrganization'} className="fw-btn-fill btn-gradient-yellow">Create Organization</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={formData.area} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Logo</th>
                {/* <th>Name</th> */}
                <th>Organization</th>

                <th>E-mail</th>
                <th>Phone</th>
                <th>Area</th>
                <th>Address</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedOrganizations">
            
              {/* Render organizations */}
              {currentOrgs.map((org, index) => (
                <tr key={index}>
                  <td><img src={org.logo} alt='Logo' width={100}></img></td>
                  {/* <td>{org.adminusername}</td> */}
                  <td>{org.adminOrganization}</td>

                  <td>{org.adminemail}</td>
                  <td>{org.adminphone}</td>
                  <td>{org.adminarea}</td>
                  <td>{org.adminaddress.split(',').map((line, index) => (
                <div key={index}>{line.trim()}</div>
              ))}</td>
                  <td><i onClick={()=>window.location='/editorganization?id='+ org.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(org.id)} className='fa fa-trash'></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredOrganizations.length / organizationsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredOrganizations.length / organizationsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default ListofOrganizations;
