import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { post } from './rest';
import { ToastContainer, toast } from 'react-toastify';

function ChangePassword() {
    const [password, setPassword] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPasswordError, setnewPasswordError] = useState("");

    function validatePassword(value) {
        if (value.length < 8 || !value.match(/[0-9]/) || !value.match(/[a-zA-Z]/)) {
            setPasswordError("Password must be at least 8 characters long and contain at least one letter and one number.");
        } else {
            setPasswordError("");
        }
    }

    async function handleChangePassword(e) {
        e.preventDefault();

        validatePassword(newPassword);
        const authorid = sessionStorage.getItem("authorid");
            try {
                const formData = {
                    oldPassword: password,
                    newPassword: newPassword,
                    userId:authorid
                };
                debugger
                const responseData = await post('/auth/change-password', formData);
                debugger
                if(responseData.statusCode === 201){
                    toast.success("Your Password Changed Successfully", {
                        onClose:() =>{
                            // sessionStorage.removeItem("authorid");
                            // sessionStorage.removeItem("authorname");
                            // sessionStorage.removeItem("roleid");
                            window.location = '/';
                        }
                    });
                } else {
                    toast.error(responseData.response);
                }
                setResponse(responseData);
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        
    }
    const [formData, setFormData] = useState({
      });
      const [response, setResponse] = useState(null);
    
    //   const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData(prevState => ({
    //       ...prevState,
    //       [name]: value
    //     }));
    //   };
    
    return (
        <>
            <div className="login-page-wrap">
                <div className="login-page-content">
                    <div className="login-box">
                        <div className="item-logo">
                            <p className="menulogoonlog">
                                Change Password
                            </p>
                        </div>
                        <Form id="salogin" className="form-box" onSubmit={handleChangePassword}>
                        <Row>
                        <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                            <Form.Group className="form-group" controlId="formBasicPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control
                                    className="form-control" 
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value); }}
                                />
                            </Form.Group>
                            </Col>
                            <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                            <Form.Group className="form-group" controlId="formBasicnewPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="password"
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={(e) => { setnewPassword(e.target.value); validatePassword(e.target.value); }}
                                />
                                {passwordError && <p className="error-message">{passwordError}</p>}
                            </Form.Group>
                            </Col>
                            </Row>
                            <Row>
                            <Button variant="primary" type="submit" className="login-btn">
                                Change Password
                            </Button>
                            </Row>
                        </Form>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
