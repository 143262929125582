import React, { useCallback, useEffect, useState } from 'react';
import '../Assets/Icons/fonts/flaticon.css';
import '../App.css'; 
import { Button } from 'react-bootstrap';
import { get } from './rest';

function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false); 
    const [menuData, setMenuData] = useState([]);
    const [superMenuData, setSuperMenuData] = useState([]);
    const [activeItemIndex, setActiveItemIndex] = useState(null); 
    const fetchMenuData = useCallback(async () => {
        try {
            const userRoleId = sessionStorage.getItem("roleid");
            const response = await fetch(`http://api.campusplus360.in/roleactivity/role/${userRoleId}`);
            const data = await response.json();
            setMenuData(data.response);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    }, []);

    useEffect(() => {
        const userRoleId = sessionStorage.getItem("roleid");
        // if(userRoleId === '65e6d3046194e28fe38ab746'){
        //     fetchSuperMenuData();
        // } else {
        //     fetchMenuData();
        // }
        fetchMenuData();
    }, [fetchMenuData]);


    const fetchSuperMenuData = async () => {
        try {
            get('activitygroups')
            .then(response => {
                const filteredSuperMenuData = response.response.filter(item => !['plans', 'results', 'students', 'website'].includes(item.name.toLowerCase()));

              setSuperMenuData(filteredSuperMenuData);
            })
            .catch(error => console.error('Error fetching data:', error));          
      
       } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleSubMenu = (index) => {
        setActiveItemIndex(index === activeItemIndex ? null : index); 
    };

    return (
        <div className={`sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color ${sidebarOpen ? 'sidebar-collapsed' : ''}`}>
            <div className="nav-bar-header-one">
                <div className="header-logo">
                    <a href="/dashboard"> 
                        <a href="/dashboard" className="menulogo">GreenBug</a>
                    </a>
                </div>
                <div className="toggle-button sidebar-toggle">
                    <Button type="button" className="item-link" onClick={toggleSidebar}>
                        <span className="btn-icon-wrap">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </Button>
                </div>
            </div>

            <div className="mobile-sidebar-header d-md-none">
                <div className="header-logo">
                    <a href="/dashboard" className="menulogo">GreenBug</a>
                </div>
            </div>

            <div className="sidebar-menu-content">
                <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                    {menuData.length > 0 ? (
                        menuData.map((item, index) => (
                            <li className="nav-item sidebar-nav-item" key={item._id}>
                                <a href={item.activity_group.url} className={`nav-link ${index === activeItemIndex ? 'active' : ''}`} onClick={() => toggleSubMenu(index)}>
                                    <i className={`fa fa-${item.activity_group.icon}`}></i><span>{item.activity_group.name}</span>
                                </a>
                                {item.activity && (
                                    <ul className={`nav sub-group-menu ${index === activeItemIndex ? 'sub-group-active' : ''}`}>
                                        {item.activity.map((subItem) => (
                                            <li className="nav-item" key={subItem._id}>
                                                <a href={subItem.url} className="nav-link">
                                                    <i className="fas fa-angle-right"></i>{subItem.name}
                                                </a>
                                            </li>  
                                        ))}                            
                                    </ul>
                                )}                        
                            </li>
                        ))
                    ) : (
                        <></>
                    )}
                    {superMenuData.length > 0 ? (
                        superMenuData.map((item, index) => (
                            <li className="nav-item sidebar-nav-item" key={item._id}>
                                <a href={item.activity.url} className={`nav-link ${index === activeItemIndex ? 'active' : ''}`} onClick={() => toggleSubMenu(index)}>
                                    <i className={`fa fa-${item.icon}`}></i><span>{item.name}</span>
                                </a>
                                {item.activity && (
                                    <ul className={`nav sub-group-menu ${index === activeItemIndex ? 'sub-group-active' : ''}`}>
                                        {item.activity.map((subItem) => (
                                            <li className="nav-item" key={subItem._id}>
                                                <a href={subItem.url} className="nav-link">
                                                    <i className="fas fa-angle-right"></i>{subItem.name}
                                                </a>
                                            </li>  
                                        ))}                            
                                    </ul>
                                )}                        
                            </li>
                        ))
                    ) : (
                        <></>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
