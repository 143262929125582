import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
function OTP() {
    
    // const [OTP, setOTP] = useState("");
    const [formData, setFormData] = useState({
      
      email: '',
      otp: ''
    });
    const [email, setEmail] = useState(null);
    const location = useLocation();
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('email');
      setEmail(id); 
      setFormData(prevState => ({
        ...prevState,
        email: id
      }));
    }, [location.search]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      const numericValue = parseInt(value, 10);
      setFormData(prevState => ({
        ...prevState,
        [name]: numericValue
      }));
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        debugger
        const responseData = await post('/auth/verify-email-otp', formData);
        if(responseData.statusCode === 201){
          toast.success("OTP verified Successfully", {
            onClose: () => {
              window.location.href='/setpassword?email=' + email;
            },
          });
        } else {
          toast.error(responseData.response);
      }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    };
  
    return (
      <>
        <div className="login-page-wrap">
          <div className="login-page-content">
            <div className="login-box">
              <div className="item-logo">
                <p className="menulogoonlog">
                  OTP
                </p>
              </div>
              <Form id="salogin" className="form-box" onSubmit={handleSubmit}>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control className="form-control" onChange={handleChange}
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    // onChange={(e) => setOTP(e.target.value)}
                  />
                </Form.Group>

  
                <Button variant="primary" type="submit" className="login-btn">
                  Submit OTP
                </Button>
              </Form>
              <div className="sign-up">Back to <a href="/">Login now!</a></div>
              <ToastContainer />
            </div>

          </div>
        </div>
      </>
    );
}

export default OTP;