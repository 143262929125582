import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function OrganizationAdmins() {
  const [organizationAdmins, setOrganizationAdmins] = useState([]);
  const [filteredOrganizationAdmins, setFilteredOrganizationAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [organizationAdminsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {

    fetchOrganizationAdmins();
  }, []);

  const fetchOrganizationAdmins = async () => {
    try {
      get('users')
        .then(response => {
          debugger
          var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var orgAdmins ='';
          if (role === 'superadmin') {
            orgAdmins = response.response.filter(admin => admin.role.slug !== "employee" && admin.role.slug !== "superadmin" );
          } else if (role === 'organizationadmin') {
            orgAdmins = response.response.filter(admin => admin.role.slug !== 'superadmin' && admin.role.slug === 'organizationadmin' && admin.role.slug !== 'employee'  && admin.organization.id === organizationid);
          }

          setOrganizationAdmins(orgAdmins);
          setFilteredOrganizationAdmins(orgAdmins);
        })
        .catch(error => console.error('Error fetching data:', error));          
  
    } catch (error) {
      console.error('Error fetching OrganizationAdmins:', error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterOrganizationAdmins(e.target.value);
  };

  const filterOrganizationAdmins = (query) => {
    const filteredOrgAdmins = organizationAdmins.filter(OrgAdmin => {
      const username = OrgAdmin.username ? OrgAdmin.username.toLowerCase() : '';
      const firstname = OrgAdmin.firstname ? OrgAdmin.firstname.toLowerCase() : '';
      const lastname = OrgAdmin.lastname ? OrgAdmin.lastname.toLowerCase() : '';
      const email = OrgAdmin.email ? OrgAdmin.email.toLowerCase() : '';
      const role = OrgAdmin.role && OrgAdmin.role.role ? OrgAdmin.role.role.toLowerCase() : '';
      const state = OrgAdmin.state ? OrgAdmin.state.toLowerCase() : '';
      const city = OrgAdmin.city ? OrgAdmin.city.toLowerCase() : '';
      const phone = OrgAdmin.phone ? OrgAdmin.phone.toLowerCase() : '';
  
      return (
        username.includes(query.toLowerCase()) ||
        firstname.includes(query.toLowerCase()) ||
        lastname.includes(query.toLowerCase()) ||
        email.includes(query.toLowerCase()) ||
        role.includes(query.toLowerCase()) ||
        state.includes(query.toLowerCase()) ||
        city.includes(query.toLowerCase()) ||
        phone.includes(query.toLowerCase())
      );
    });
  
    setFilteredOrganizationAdmins(filteredOrgAdmins);
    setCurrentPage(1);
  };
  
  

  const indexOfLastOrgAdmin = currentPage * organizationAdminsPerPage;
  const indexOfFirstOrgAdmin = indexOfLastOrgAdmin - organizationAdminsPerPage;
  const currentOrgAdmins = filteredOrganizationAdmins.slice(indexOfFirstOrgAdmin, indexOfLastOrgAdmin);
  async function handleDelete(Id) {
    try {
      await del(`users/${Id}`);
      // Refresh industries after deletion
      fetchOrganizationAdmins();
    } catch (error) {
      console.error('Error deleting industries:', error);
    }
  }
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredOrganizationAdmins.length / organizationAdminsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredOrganizationAdmins.length / organizationAdminsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <DashboardContent pageTitle="List of Users">
      <div>
        <Row className='text-right my-5 d-flex align-items-center'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createuser'} className="fw-btn-fill btn-gradient-yellow">Create User</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={formData.area} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Username</th>
                <th>First name</th>
                <th>Last name</th>
                <th>E-mail</th>
                <th>Phone</th>
                <th>Role</th>
                <th>State</th>
                <th>City</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedusers">
           
              {currentOrgAdmins.filter(OrgAdmin => OrgAdmin.role.slug !== 'employee').map((OrgAdmin, index) => (
                <tr key={index}>
                  <td>{OrgAdmin.username}</td>
                  <td>{OrgAdmin.firstname}</td>
                  <td>{OrgAdmin.lastname}</td>
                  <td>{OrgAdmin.email}</td>
                  <td>{OrgAdmin.phoneNumber}</td>
                  <td>{OrgAdmin.role.role}</td>
                  <td>{OrgAdmin.state}</td>
                  <td>{OrgAdmin.city}</td>
                  <td><i onClick={()=>window.location='/edituser?id='+ OrgAdmin.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(OrgAdmin.id)} className='fa fa-trash'></i></td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredOrganizationAdmins.length / organizationAdminsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredOrganizationAdmins.length / organizationAdminsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default OrganizationAdmins;
