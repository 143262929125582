import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Assessments() {
    const [assessments, setAssessments] = useState([]);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [assessmentsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {  
      fetchAssessments();
    }, []);
  
    const fetchAssessments = async () => {
      try {
        get('assessmentquiz')
          .then(response => {
            debugger
            var role = sessionStorage.getItem("roleslug");
            var qundata ='';
            // if (role === 'superadmin') {
            //   qundata = response.response;
            // } else if (role === 'organizationadmin') {
            //   qundata = response.response.filter(admin => admin.organization.id === organizationid);
            // }
            if(role === 'superadmin'){
              qundata = response.response.filter(qun => !qun.organization ); 
            } else if (role === 'organizationadmin'){
              // qundata = response.response.filter(qun => qun.organization && qun.organization === organizationid);
              qundata = response.response;
            } else {
              qundata = response.response;
            }
            debugger;
            setAssessments(qundata);
            setFilteredAssessments(qundata);
          })
          .catch(error => console.error('Error fetching data:', error));            
      } catch (error) {
        console.error('Error fetching Assessments:', error);
      }
    };
  
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterAssessments(e.target.value);
    };
  
    const filterAssessments = (query) => {
      const filteredQuizs = assessments.filter(quiz => 
        (quiz.title && quiz.title.toLowerCase().includes(query.toLowerCase())) ||
        (quiz.course && quiz.course.course_title.toLowerCase().includes(query.toLowerCase())) 
      );
      setFilteredAssessments(filteredQuizs);
      setCurrentPage(1);
    };
    
    const indexOfLastquiz = currentPage * assessmentsPerPage;
    const indexOfFirstquiz = indexOfLastquiz - assessmentsPerPage;
    const currentquizs = filteredAssessments.slice(indexOfFirstquiz, indexOfLastquiz);
    async function handleDelete(Id) {
      try {
        await del(`assessmentquiz/${Id}`);
        fetchAssessments();
      } catch (error) {
        console.error('Error deleting Assessments:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredAssessments.length / assessmentsPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredAssessments.length / assessmentsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="Assessments">
  <div>
        {/* <Row className='text-right my-5'>
          <Col md={{span:3, offset:9}}>
          <Button type="button" onClick={()=>window.location='/createquestion'} className="fw-btn-fill btn-gradient-yellow">Create Question</Button>
          </Col>
        </Row> */}

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
          <Col md={6} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control"  />
          </Col>
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Title</th>
                <th>Course</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedAssessments">
              {currentquizs.map((quiz, index) => (
                <tr key={index}>
                   <td dangerouslySetInnerHTML={{ __html: quiz.title }}></td>
                   <td>{quiz.course && quiz.course.course_title ? quiz.course.course_title : "" }</td>
                   <td> <i onClick={() => window.location = '/editassessments?id=' + quiz.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i>
                    <i onClick={() => handleDelete(quiz.id)} className='fa fa-trash'></i>
                    </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredAssessments.length / assessmentsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Assessments;
