import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectField from "./SelectField";
import { ToastContainer, toast } from "react-toastify";
import { get, put } from "./rest";
import { useLocation } from "react-router-dom";

function UpdateProfile() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    role: "",
  });

  const [id, setId] = useState(null);
  const location = useLocation();
  const [selectedOptionRoles, setSelectedOptionRoles] = useState(null);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setId(id);
    fetchProfiles(id);
  }, [location.search]);
  useEffect(() => {
    fetchRolesOptions(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRolesOptions = async () => {
    try {
      const response = await get("roles");
      debugger;
      const options = response.response.map((role) => ({
        value: role.role,
        label: role.role,
        isSelected: role.role === selectedOptionRoles,
      }));
      setOptionsRoles(options); // Set optionsRoles directly from the response
    } catch (error) {
      console.error("Error fetching roles options:", error);
    }
  };
  const fetchProfiles = async (id) => {
    try {
      get("users/" + id)
        .then((response) => {
          debugger;
          setProfiles(response.response);
          setFormData(response.response);
          setSelectedOptionRoles(response.response.role);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } catch (error) {
      console.error("Error fetching Profiles:", error);
    }
  };

  //   useEffect(() => {
  //     console.log("useEffect triggered");
  //     if (formData.role) {
  //         const defaultOption = optionsRoles.find(option => option.value === formData.role);
  //         console.log("Default Option:", defaultOption);
  //         setSelectedOptionRoles(defaultOption);
  //     }
  // }, [formData.role, optionsRoles]);
  const [phoneError, setPhoneError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

  if (name === 'phone') {
    // Remove any non-numeric characters
    newValue = value.replace(/\D/g, '');

    // Restrict to 10 digits
    newValue = newValue.slice(0, 10);

    // Check if newValue is different from original value (means non-numeric characters were entered)
    if (newValue !== value) {
      setPhoneError('Phone number should contain only numbers.');
    } else if (newValue.length !== 10) {
      // Validate if the phone number has exactly 10 digits
      setPhoneError('Phone number must be 10 digits long.');
    } else {
      setPhoneError('');
    }
  }
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleChangeRoles = (selectedOptionRoles) => {
    setSelectedOptionRoles(selectedOptionRoles);
    setFormData((prevState) => ({
      ...prevState,
      role: selectedOptionRoles.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      debugger
      const responseData = await put("users/" + id, formData);
      debugger;
      if (responseData.statusCode === 200) {
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href='/profile?id='+ id ;
          },
        });
        sessionStorage.removeItem("authorname");
        sessionStorage.setItem("authorname", formData.username);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <DashboardContent pageTitle="Edit Profile">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3> Edit Profile </h3>
                </div>
              </div>
              <div className="student-info">
                <Form onSubmit={handleSubmit} id="edit" className="mt-5">
                  <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label >
                         Username:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="username"
                        name="username"
                        className="form-control"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Password:</Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </Col> */}
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>First name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="firstname"
                        name="firstname"
                        className="form-control"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label >Last name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="lastname"
                        name="lastname"
                        className="form-control"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </Col> */}
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>Phone:</Form.Label>
                      <Form.Control
                        type="tel"
                        id="phone"
                        name="phone"
                        className={`form-control ${phoneError ? 'is-invalid' : ''}`}
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>City:</Form.Label>
                      <Form.Control
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        type="text"
                        id="state"
                        name="state"
                        className="form-control"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group d-none">
                      <Form.Label>Role:</Form.Label>
                      <SelectField
                        options={optionsRoles}
                        onChange={handleChangeRoles}
                        selected={formData.role.id}
                      />
                    </Col>
                    
                    {/* <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                     </Col> */}
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update  Profile
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default UpdateProfile;
