import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { get, post } from './rest';
import { ToastContainer, toast } from 'react-toastify';
import SubscriptionSelectField from './SubscriptionSelectField';
import { debounce } from 'chart.js/helpers';

function CreateOrganization() {
  const [formData, setFormData] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [optionsSubscriptions, setOptionsSubscriptions] = useState([]);
  const [logoURL, setLogoURL] = useState("");
  const [uploading, setUploading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [email, setEmail] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    fetchSubscriptionOptions();
  }, []);
  useEffect(() => {
    validateForm(formData); // Validate form whenever formData changes
  }, [formData]);

  const handleEmailChange = debounce(async (value) => {
    try {
        const checkEmailResponse = await post(`/auth/check-email-exists`, { email: value });
        setEmailExists(checkEmailResponse.response);
        if (checkEmailResponse.response === true) {
            toast.error('Email already exists!');
        } else {
          setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                adminemail: value
            };
            console.log("Updated formData:", updatedFormData);
            return updatedFormData;
        });
        
        }
    } catch (error) {
        console.error('Error checking email:', error);
    }
}, 300); // Adjust the delay as needed
const onEmailInputChange = (e) => {
  const { value } = e.target;
  setEmail(value);
  handleEmailChange(value); // Call the debounced function
};
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "adminphone") {
      newValue = value.replace(/\D/g, "");
      newValue = newValue.slice(0, 10);

      if (newValue !== value) {
        setPhoneError("Phone number should contain only numbers.");
      } else if (newValue.length !== 10) {
        setPhoneError("Phone number must be 10 digits long.");
      } else {
        setPhoneError("");
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    validateForm({ ...formData, [name]: newValue });
  };

  const handleLogoChange = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    formData.append("path", "prod/default/Organization/Logos");

    try {
      const response = await fetch("http://api.campusplus360.in/upload", {
        method: "POST",
        body: formData,
      });
      if (response.status === 201) {
        const data = await response.json();
        if (data.response.length > 0) {
          const firstObject = data.response[0];
          const url = firstObject.url;
          setLogoURL(url);
          setFormData(prevState => ({
            ...prevState,
            logo: url
          }));
          toast.success("Logo Uploaded successfully");
        }
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image");
    } finally {
      setUploading(false);
    }
  };

  const fetchSubscriptionOptions = async () => {
    try {
      const response = await get("subscriptiontype");
      const options = response.response.map((sub) => ({
        value: sub.id,
        label: sub.subscriptiontype + "(" + sub.price + ")",
      }));
      setOptionsSubscriptions(options);
    } catch (error) {
      console.error("Error fetching subscription options:", error);
    }
  };

  const handleChangeSubscription = (selectedSubscription) => {
    const { value, label } = selectedSubscription;
    const subscriptionTypeName = label.replace(/\([^)]+\)/, '').trim();
    const subscriptionType = value;
    const price = label.match(/\(([^)]+)\)/)[1];
debugger
    setSelectedSubscription(selectedSubscription);
    setFormData((prevState) => ({
      ...prevState,
      subscriptionTypeName: subscriptionTypeName,
      subscriptionType: subscriptionType,
      price: price,
      isActive: false
    }));
  };

  const validateForm = (formData) => {
    const { adminemail, adminphone, adminOrganization, adminarea, adminaddress } = formData;
    const isValid = adminemail && adminphone && adminOrganization && adminarea && adminaddress && !phoneError;
    setIsButtonEnabled(isValid);
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleRazorpayPayment = async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: "rzp_test_VLnzWA84IIIUs4",
      amount: formData.price * 100,
      currency: "INR",
      name: formData.adminOrganization,
      description: "Subscription Payment",
      image: logoURL,
      handler: async function (response) {
        toast.success(`Payment successful! Razorpay Payment ID: ${response.razorpay_payment_id}`);

        // Add logic to submit organization data after payment
        await submitOrganizationData();
      },
      prefill: {
        name: formData.firstname,
        email: email,
        contact: formData.phoneNumber,
      },
      notes: {
        address: formData.adminaddress,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const submitOrganizationData = async () => {
    try {
      const orgData = {
        adminemail: email,
        adminOrganization: formData.adminOrganization,
        adminphone: formData.adminphone,
        adminarea: formData.adminarea,
        adminaddress: formData.adminaddress,
        logo: formData.logo,
        price: formData.price,
        subscriptionType: formData.subscriptionType,
      };

      const enquiryRes = await post("/organization", orgData);
      if (enquiryRes.statusCode === 201) {
        toast.success("Organization created successfully");
        window.location.href = '/listofOrganization';
      }
    } catch (error) {
      console.error("Error submitting organization data:", error);
      toast.error("Failed to submit organization data.");
    }
  };

  const handleCashPayment = async () => {
    // Call submitOrganizationData directly for cash payments
    await submitOrganizationData();
  };

  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  return (
    <DashboardContent pageTitle="Organization">
    <Row>
      <Col md={12}>
        <div className="card dashboard-card-ten">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Create Organization</h3>
              </div>
            </div>
            <div className="student-info">
            <Form id="subscriptionPayment" className="">
              <Row>
              <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>
                    Organization Name:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="adminOrganization"
                    name="adminOrganization"
                    className="form-control"
                    value={formData.adminOrganization || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>Logo:</Form.Label>
                  <div className="wrapper">
                    <div className="box">
                      <div className="js--image-preview"></div>
                      <div className="upload-options">
                        <Form.Control
                          type="file"
                          id="logo"
                          className="image-upload"
                          accept="image/*"
                          onChange={handleLogoChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                </Row>
                <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label >Username:</Form.Label>
                    <Form.Control type="text" id="username" name="username" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" id="password" name="password" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>First name:</Form.Label>
                    <Form.Control type="text" id="firstname" name="firstname" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label >Last name:</Form.Label>
                    <Form.Control type="text" id="lastname" name="lastname" className="form-control" onChange={handleChange} required />
                  </Col>
                  </Row>
              <Row>
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    id="adminemail"
                    name="adminemail"
                    className="form-control"
                    value={email || ""}
                    onChange={onEmailInputChange}
                    required
                  />
                </Col>
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                    type="tel"
                    id="adminphone"
                    name="adminphone"
                    className="form-control"
                    value={formData.adminphone || ""}
                    onChange={handleChange}
                    required
                  />
                  {phoneError && <p className="text-danger">{phoneError}</p>}
                </Col>
                </Row>
              <Row>
              
                
                </Row>
              <Row>
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>Area:</Form.Label>
                  <Form.Control
                    type="text"
                    id="adminarea"
                    name="adminarea"
                    className="form-control"
                    value={formData.adminarea || ""}
                    onChange={handleChange}
                    required
                  />
                </Col>
              
               
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                  <Form.Label>Subscriptions:</Form.Label>
                  <SubscriptionSelectField
                    options={optionsSubscriptions}
                    onChange={handleChangeSubscription}
                    placeholder="Select Subscription Type"
                    selected={selectedSubscription}
                  />
                </Col>
                </Row>
              <Row>
                
                </Row>
                <Row>
                <Col xs={8} className="form-group">
                  <Form.Label>Address:</Form.Label>
                  <textarea
                    id="adminaddress"
                    name="adminaddress"
                    className="form-control"
                    value={formData.adminaddress || ""}
                    onChange={handleChange}
                    required
                  ></textarea>
                </Col>
                </Row>
                <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>City:</Form.Label>
                    <Form.Control type="text" id="city" name="city" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>State:</Form.Label>
                    <Form.Control type="text" id="state" name="state" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col xs={8} className="form-group">
                  <Form.Label>Note:</Form.Label>
                  <textarea
                    id="adminnote"
                    name="adminnote"
                    className="form-control"
                    value={formData.adminnote || ""}
                    onChange={handleChange}
                    required
                  ></textarea>
                </Col>
                  </Row>
                <Row>
                <Col md={4} xl={4} lg={4} xs={8} className="form-group">
                <Button
                    type="button"
                    onClick={openPaymentModal}
                    className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                    disabled={!isButtonEnabled}
                  >
                    Submit & Pay 
                  </Button>
                </Col>
              </Row>
            </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <ToastContainer />
    <Modal show={showPaymentModal} onHide={closePaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <div className="d-flex justify-content-around">
    <Button
      className='secondary-btn'
      onClick={() => {
        closePaymentModal();
        handleRazorpayPayment();
      }}
    >
      Pay with Razorpay
    </Button>
    <Button
      className='secondary-btn'
      onClick={() => {
        closePaymentModal();
        handleCashPayment();
      }}
    >
      Pay with Cash
    </Button>
  </div>
</Modal.Body>
<Modal.Footer>
  <Button variant="danger" onClick={closePaymentModal}>
    Cancel
  </Button>
</Modal.Footer>

            </Modal>
    </DashboardContent>
    
  );
}

export default CreateOrganization;
