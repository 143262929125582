import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import LoginSuperAdmin from "./Components/LoginSuperAdmin";
import ForgotPassword from "./Components/ForgotPassword";
import OTP from "./Components/OTP";
import ChangePassword from "./Components/ChangePassword";
import Dashboard from "./Components/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import Enquiries from "./Components/Enquiries";
import ListofOrganizations from "./Components/ListofOrganizations";
import EditOrganization from "./Components/EditOrganization";
import Profile from "./Components/Profile";
import UpdateProfile from "./Components/UpdateProfile";
import DefaultCourse from "./Components/DefaultCourse";
import CreateDefaultCourse from "./Components/CreateDefaultCourse";
import EditDefaultCourse from "./Components/EditDefaultCourse";
import Industries from "./Components/Industries";
import CreateIndustry from "./Components/CreateIndustry";
import EditIndustry from "./Components/EditIndustry";
// import ChaptersList from "./Components/ChaptersList";
import CreateChapter from "./Components/CreateChapter";
import AssignRoles from "./Components/AssignRoles";
import CreateRoles from "./Components/CreateRoles";
import ListofRoles from "./Components/ListofRoles";
import EditRole from "./Components/EditRole";
import Subscriptions from "./Components/SubscriptionTypes";
import CreateSubscriptionType from "./Components/CreateSubscriptionType";
import EditSubscriptionType from "./Components/EditSubscriptionType";
import SubscriptionPayment from "./Components/SubscriptionPayment";
import Employees from "./Components/Employees";
import CreateEmployee from "./Components/CreateEmployee";
import EditEmployee from "./Components/EditEmployee";
import CreateOrganization from "./Components/CreateOrganization";
import RolesAssigned from "./Components/RolesAssigned";
import Assessments from "./Components/Assessments";
import CreateAssessment from "./Components/CreateAssessment";
import EditAssessment from "./Components/EditAssessment";
import CreateLMS from "./Components/CreateLMS";
import OrganizationAdmins from "./Components/OrganizationAdmins";
import CreateOrganizationAdmin from "./Components/CreateOrganizationAdmin";
import EditOrganizationAdmin from "./Components/EditOrganizationAdmin";
// import Voucher from "./Components/Vochuer";
import ListOfLMS from "./Components/ListOfLMS";
import EditLMS from "./Components/EditLMS";
import SetNewPassword from "./Components/SetNewPassword";
import EditRoleAssigned from "./Components/EditRoleAssigned";
import WebsiteSetUp from "./Components/WebsiteSetUp";
import Results from "./Components/Results";
import Students from "./Components/Students";
import CreateStudent from "./Components/CreateStudent";
import EditStudent from "./Components/EditStudent";
import CreateQuestion from "./Components/CreateQuestion";
import Modules from "./Components/Modules";
import Chapters from "./Components/Chapters";
import Questions from "./Components/Questions";
import FillInTheBlanks from "./Components/FillInTheBlanks";
import EditFillintheBlank from "./Components/EditFillintheBlank";
import EditAssessmentQuiz from "./Components/EditAssessmentQuiz";
import CreateChooseCorrect from "./Components/CreateChooseCorrect";
import EditChooseCorrect from "./Components/EditChooseCorrect";
import { useEffect } from "react";
import Messages from "./Components/Messages";
import CreateIssue from "./Components/CreateIssue";
import ViewChat from "./Components/ViewChat";

function App() {

// Get the current page URL
var currentPage = window.location.pathname;

// Check if the authorid is not present in session storage
if (!sessionStorage.getItem("authorid")) {
  // Define an array of exceptions (pages where redirection is not needed)
  var exceptions = ["/forgotpassword", "/otp", "/setpassword", "/", "/subscriptionpayment"];

  // Check if the current page is not in the exceptions array
  if (!exceptions.includes(currentPage)) {
      // Redirect to the login page
      window.location.href = "/";
  }
} else {
  // Define an array of exceptions for logged-in users (pages that should redirect to the dashboard)
  var dashboardExceptions = ["/forgotpassword", "/otp", "/setpassword", "/"];

  // Check if the current page is one of the dashboard exceptions
  if (dashboardExceptions.includes(currentPage)) {
    // Redirect to the dashboard page
    window.location.href = "/dashboard";
  }
}
// Function to add asterisks to required fields
const addAsterisksToRequiredLabels = () => {
  const requiredFields = document.querySelectorAll('.form-control[required]');
  requiredFields.forEach(field => {
    // Find the label associated with the current field
    const label = findLabelForField(field);
    if (label && !label.innerHTML.includes('*')) {
      label.innerHTML += '<span class="text-danger"> *</span>';
    }
  });
};

// Function to find the label associated with a form field
const findLabelForField = (field) => {
  // Check if the field itself is labeled
  let label = field.closest('label');
  if (!label) {
    // If not, find the nearest label in the ancestor tree
    label = field.closest('div').querySelector('label');
  }
  return label;
};

useEffect(() => {
  // Initial run to add asterisks
  addAsterisksToRequiredLabels();

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(() => {
    addAsterisksToRequiredLabels();
  });

  // Observe changes in the entire body
  observer.observe(document.body, { childList: true, subtree: true });

  // Cleanup observer on unmount
  return () => {
    observer.disconnect();
  };
}, []);




  return (
    <div className="App">
      <BrowserRouter>
        {/* This routes for non sidebar pages */}
        <Routes>
          <Route path="/" element={<LoginSuperAdmin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/setpassword" element={<SetNewPassword />} />
          <Route path="/otp" element={<OTP />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/enquiries" element={<Enquiries />} />
          <Route path="/createorganization" element={<CreateOrganization />} />
          <Route path="/listoforganization" element={<ListofOrganizations />} />
          <Route path="/editorganization" element={<EditOrganization />} />
          <Route path="/roleassign" element={<EditOrganization />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/course" element={<DefaultCourse />} />
          <Route path="/createcourse" element={<CreateDefaultCourse />} />
          <Route path="/editcourse" element={<EditDefaultCourse />} />
          <Route path="/categories" element={<Industries />} />
          <Route path="/createindustry" element={<CreateIndustry />} />
          <Route path="/editindustry" element={<EditIndustry />} />
          {/* <Route path="/chapters" element={<ChaptersList />} /> */}
          <Route path="/createchapter" element={<CreateChapter />} />
          <Route path="/roles" element={<ListofRoles />} />
          <Route path="/assignroles" element={<AssignRoles />} />
          <Route path="/createroles" element={<CreateRoles />} />
          <Route path="/editroles" element={<EditRole />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/createsubscriptiontype" element={<CreateSubscriptionType />} />
          <Route path="/editsubscriptiontype" element={<EditSubscriptionType />} />
          <Route path="/subscriptionpayment" element={<SubscriptionPayment />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/createemployee" element={<CreateEmployee />} />
          <Route path="/editemployee" element={<EditEmployee />} />
          <Route path="/rolesassigned" element={<RolesAssigned />} />
          <Route path="/editrolesassigned" element={<EditRoleAssigned />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route path="/editassessments" element={<EditAssessmentQuiz />} />
          <Route path="/questionbank" element={<Questions />} />
          <Route path="/createquestion" element={<CreateQuestion />} />
          <Route path="/create-assessment" element={<CreateAssessment />} />
          <Route path="/fill-in-the-blanks" element={<FillInTheBlanks />} />
          <Route path="/editfillintheblank" element={<EditFillintheBlank />} />
          <Route path="/editquestion" element={<EditAssessment />} />
          <Route path="/createchooseTheCorrectAnswer" element={<CreateChooseCorrect />} />
          <Route path="/editchooseTheCorrectAnswer" element={<EditChooseCorrect />} />
          <Route path="/createlms" element={<CreateLMS />} />
          <Route path="/users" element={<OrganizationAdmins />} />
          <Route path="/createuser" element={<CreateOrganizationAdmin />} />
          <Route path="/edituser" element={<EditOrganizationAdmin />} />
          <Route path="/listoflms" element={<ListOfLMS />} />
          <Route path="/editlms" element={<EditLMS />} />
          <Route path="/websitesetup" element={<WebsiteSetUp />} />
          <Route path="/results" element={<Results />} />
          <Route path="/students" element={<Students />} />
          <Route path="/createstudent" element={<CreateStudent />} />
          <Route path="/editstudent" element={<EditStudent />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/units" element={<Chapters />} />
          <Route path="/chats" element={<Messages />} />
          <Route path="/createmessage" element={<CreateIssue />} />
          <Route path="/viewchat" element={<ViewChat />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
