import React, { useEffect, useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { get, put } from './rest';
import { ToastContainer, toast } from 'react-toastify';

function EditOrganization() {
  const [formData, setFormData] = useState({
    // adminusername: '',
    // adminpassword: '',
    adminemail: '',
    adminphone: '',
    adminOrganization: '',
    adminarea: '',
    adminaddress: '',
    isActive: true
  });
  const [id, setId] = useState(null);
  const location = useLocation();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    setId(id); // Set the ID value in the state variable
    fetchOrganization(id); // Fetch organization data based on the ID
  }, [location.search]);

  const [organization, setOrganization] = useState([]);

  const fetchOrganization = async (id) => {
    try {
      const response = await get('organization/' + id);
      setOrganization(response.response);
      // Update form data with fetched organization data
      setFormData(response.response);
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  const [response, setResponse] = useState(null);
  const [logoURL, setLogoURL] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'adminphone') {
      // Remove any non-numeric characters
      newValue = value.replace(/\D/g, '');
  
      // Restrict to 10 digits
      newValue = newValue.slice(0, 10);
  
      // Check if newValue is different from original value (means non-numeric characters were entered)
      if (newValue !== value) {
        setPhoneError('Phone number should contain only numbers.');
      } else if (newValue.length !== 10) {
        // Validate if the phone number has exactly 10 digits
        setPhoneError('Phone number must be 10 digits long.');
      } else {
        setPhoneError('');
      }
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };
  const handleLogoChange = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', 'prod/default/Organization/Logos'); 
  
    try {
        debugger
        for (let pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
      }
        const response = await fetch('https://api.campusplus360.in/upload', {
            method: 'POST',
            body: formData,
        }); 
        if (!response.ok) {
          const errorResponse = await response.json(); // Get error details from the response
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorResponse.message}`);
      }
        if (response.status === 201) { // Checking for response.ok instead of statusCode === 200
            const data = await response.json(); // Assuming the response is in JSON format
            debugger
            if (data.response.length > 0) {
              const firstObject = data.response[0]; // Accessing the first object in the array
              const url = firstObject.url; // Assuming the URL key is 'url' in the object
              setLogoURL(url); // Set the Logo URL
              setFormData(prevState => ({
                ...prevState,
                logo: url
              }));
              toast.success('Logo Uploaded successfully');
            } 
        } else {
            toast.error('Failed to upload image');
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image');
    } finally {
      setUploading(false); // Set uploading to false when upload completes
    }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        logo: logoURL || formData.logo
      };

      const responseData = await put('organization/' +id , updatedFormData);
      setResponse(responseData);
      debugger
      if(responseData.statusCode === 200){
        toast.success("Updated Successfully", {
          onClose: () => {
            window.location.href='/listofOrganization';
          },
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <DashboardContent pageTitle="Edit Organization">
      <Row>
        <Col md={12}>
          <div className="card dashboard-card-ten">
            <div className="card-body">
              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Edit Organization Admin</h3>
                </div>
              </div>
              <div className="student-info">
              <Form onSubmit={handleSubmit} id="editAdmin" className="mt-5">
              <Row>
                  {/* <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label htmlFor="adminusername">User Name:</Form.Label>
                    <Form.Control type="text" id="adminusername" name="adminusername" className="form-control" value={formData.adminusername} onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label htmlFor="adminpassword">Password:</Form.Label>
                    <Form.Control type="password" id="adminpassword" name="adminpassword" className="form-control" value={formData.adminpassword} onChange={handleChange} required />
                  </Col> */}
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" id="adminemail" name="adminemail" className="form-control" value={formData.adminemail} onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Phone:</Form.Label>
                    <Form.Control type="tel" id="adminphone" name="adminphone" className={`form-control ${phoneError ? 'is-invalid' : ''}`} value={formData.adminphone} onChange={handleChange} required />
                    {phoneError && <div className="invalid-feedback">{phoneError}</div>}

                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Organization:</Form.Label>
                    <Form.Control type="text" id="adminOrganization" name="adminOrganization" className="form-control" value={formData.adminOrganization} onChange={handleChange} required />
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Area:</Form.Label>
                    <Form.Control type="text" id="adminarea" name="adminarea" className="form-control" value={formData.adminarea} onChange={handleChange} required />
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Logo:</Form.Label>
                      <div className="wrapper">
                        <div className="box">
                          <div className="js--image-preview"></div>
                          <div className="upload-options">
                              <Form.Control type="file" id="logo" className="image-upload" accept="image/*" onChange={handleLogoChange} />
                            <img src={formData.logo} alt="thumnail" width={100} className='mt-3'></img>
                          </div>
                        </div>
                      </div>
                    </Col>
                  
                  </Row>
                    <Row>
                  <Col md={8} className="form-group">
                    <Form.Label>Address:</Form.Label>
                    <textarea id="adminaddress" name="adminaddress" className="form-control" value={formData.adminaddress} onChange={handleChange} required></textarea>
                  </Col>
                  <Col xs={8} className="form-group">
                  <Form.Label>Note:</Form.Label>
                  <textarea
                    id="adminnote"
                    name="adminnote"
                    className="form-control"
                    value={formData.adminnote || ""}
                    onChange={handleChange}
                    required
                  ></textarea>
                </Col>
                  </Row>
                  {/* <Row>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>City:</Form.Label>
                      <Form.Control type="text" id="city" name="city" className="form-control" onChange={handleChange} required />
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>State:</Form.Label>
                      <Form.Control type="text" id="state" name="state" className="form-control" onChange={handleChange} required />
                    </Col>
                  </Row> */}
                  <Row>
                  <Col md={2} xl={2} lg={2} xs={2} className="form-group d-flex justify-content-around">                  
                    <Form.Check
                      type="checkbox"                                            
                      checked={formData.isActive}
                      onChange={(e) => setFormData(prevState => ({
                        ...prevState,
                        isActive: e.target.checked // Update isActive based on checkbox
                    }))}
                    
                    />
                                      <Form.Label className='m-0'> Active</Form.Label>

                    {/* <input
                  type="checkbox"
                  value={detail[answer]}
                  checked={selectedOption.includes(answer)}
                  onChange={() => handleOptionChange(assessmentId, questionId, answer, detail[answer], true)}
                  style={{ marginRight: '10px' }}
                /> */}
                  </Col>
                  
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group"> 
                  <Button type="submit" disabled={uploading} className="mt-4 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        {uploading ? 'Uploading...' : 'Update Organization'}
                      </Button>
                  </Col>
                </Row>
              </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default EditOrganization;
