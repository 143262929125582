import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format, isToday, isThisWeek, parseISO } from 'date-fns';
import { get, put } from './rest';
import DOMPurify from 'dompurify';

function Header() {
  const authorid = sessionStorage.getItem("authorid");
  const authorname = sessionStorage.getItem("authorname");
  const authorrole = sessionStorage.getItem("rolename");
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const handleLogout = () => {
    if (authorid) {
      sessionStorage.removeItem("authorid");
      sessionStorage.removeItem("authorname");
      sessionStorage.removeItem("roleid");
      sessionStorage.removeItem("roleslug");
      sessionStorage.removeItem("rolename");
      sessionStorage.removeItem("organizationid");
      window.location.href = "/";
    }
  };
  useEffect(() => {  
    fetchNotifications();
}, []);
const handleNotificationClick = async (notification) => {
    try {
        const formData = {
            isRead : true
        }
        debugger;
        // Update the notification to mark it as read
        await put(`notifications/${notification.id}`, formData);
        
        // Fetch the notifications again to update the list
        fetchNotifications();
        
        // Handle the link navigation
        if (notification.link) {
            if (notification.link.startsWith('http')) {
                window.open(notification.link, '_blank');
            } else {
                // Handle internal links
                if (notification.type && notification.type === "quizcalendar") {
                    window.location.href = notification.link;
                } else {
                    window.location.href = notification.link;
                }
            }
        }
    } catch (error) {
        console.error('Error updating notification:', error);
    }
};
const fetchNotifications = async () => {
    try {
        const response = await get('notifications');
        const authorId = sessionStorage.getItem("authorid");
        const notificationslist = response.response.filter(qun => qun.user && qun.user.id === authorId && qun.isRead === false && qun.type === 'chat');

        debugger
        setNotifications(notificationslist);
        setNotificationsCount(notificationslist.length);
    } catch (error) {
        console.error('Error fetching notifications:', error);
    }
};

const formatCreatedAt = (dateString) => {
  try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
          throw new Error("Invalid date");
      }

      if (isToday(date)) {
          return format(date, 'HH:mm'); // Show time without seconds if the date is today
      }

      if (isThisWeek(date)) {
          return format(date, 'EEEE, MMM d'); // Show week day and date if the date is in the same week
      }

      return format(date, 'EEE, MMM d'); // Show date and week day for other dates
  } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid date"; // Fallback for invalid dates
  }
};
  return (
    <Navbar bg="light" expand="md" className="header-main-menu">
      <div className='d-flex align-items-center'>
             {authorname ? (
                                <h5 className="item-title me-2">{authorname}</h5>
                            ):''} <p className='mb-2'> ({authorrole})</p>
            </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent:'flex-end'}}>
        <Nav className="">
        <ul className="navbar-nav">
                    <li className="navbar-item header-search-bar">
                        <div className="input-group stylish-input-group">
                            {/* <span className="input-group-addon">
                                <button type="submit">
                                    <span className="flaticon-search" aria-hidden="true"></span>
                                </button>
                            </span>
                            <Form.Control type="text" className="form-control" placeholder="Find Something . . ." /> */}
                        </div>
                    </li>
                </ul>
        </Nav>
        <Nav className="ms-auto navbar-nav header-message">
                    <NavDropdown className='navbar-nav-link' title={
                        <>
                            <i className="far fa-bell"></i>
                            {notificationsCount > 0 && <span className='header-number'>{notificationsCount}</span>}
                        </>
                    } id="message-nav-dropdown">
                      <div style={{height:400, overflowY: 'scroll'}}>
                        <h6 className="item-title d-md-none text-16 mg-l-10">Notifications</h6>
                        <div className="item-header">
                            <h6 className="item-title">{notificationsCount} Notifications</h6>
                        </div>
                        {notifications.length > 0 ? (
                                notifications.map(notification => (
                                    <Dropdown.Item key={notification.id} onClick={() => handleNotificationClick(notification)}>
                                        <div>
                                            <ul className="item-content">
                                                <li>
                                                    <div className="media">
                                                        <div className="item-title">
                                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.message) }}></div>
                                                            {notification.link && (
                                                                notification.link.startsWith('http') ? (
                                                                    <a href={notification.link} target="_blank" rel="noopener noreferrer">Click here</a>
                                                                ) : (
                                                                    notification.type && notification.type === "chat" ? (
                                                                        
                                                                        <Link to={notification.link}>Check and Reply - Click here</Link>
                                                                    ) : (
                                                                        <Link to={notification.link}>Get Your Link - Click here</Link>
                                                                    )
                                                                )
                                                            )}
                                                            <p className="item-time">{formatCreatedAt(notification.createdAt)}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item>
                                    <div>
                                        <ul className="item-content">
                                            <li>
                                                <p>No messages available.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown.Item>
                            )}
                        </div>
                    </NavDropdown>
                    <NavDropdown title={
                        <div className="navbar-item dropdown header-admin">
                            <a className="navbar-nav-link dropdown-toggle" href="#!" role="button" data-toggle="dropdown" aria-expanded="false">
                                
                                <div className="admin-img px-4">
                                    <i className='fa-regular fa-user'></i>
                                </div>
                            </a>
                        </div>
                    } id="user-nav-dropdown">
                        <NavDropdown.Item className="admin-title">
                {authorname ? (
                    <h5 className="item-title">{authorname}</h5>
                ):''}
                    <span>{authorrole}</span>
                </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/profile?id=${authorid}`}>
                            <i className="fa-solid fa-user"></i>My Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/updateprofile?id=${authorid}`}>
                            <i className="fa fa-gear"></i>Account Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/changepassword?id=${authorid}`}>
                            <i className="fa fa-unlock"></i>Update Password
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#!" id='logout' onClick={handleLogout}>
                            <i className="fa-solid fa-power-off"></i>Log Out
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
