import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { get,del } from './rest'


function ListOfLMS() {
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [organizationsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
        get('lms')
            .then(response => {
                debugger;
                console.log(response);
                var role = sessionStorage.getItem("roleslug");
                var organizationid = sessionStorage.getItem("organizationid");
                var lmsdata = '';

                if (role === 'superadmin') {
                    // Filter data for superadmin
                    lmsdata = response.response.filter(lms => !lms.organization);
                } else if (role === 'organizationadmin') {
                  lmsdata = response.response.filter(lms => {
                    return lms.organization && lms.lms_cluster !== null && lms.organization === organizationid && lms.lms_cluster.name === 'organizationadmin';
                });
                }

                debugger;
                setOrganizations(lmsdata);
                setFilteredOrganizations(lmsdata);
            })
            .catch(error => console.error('Error fetching data:', error));
    } catch (error) {
        console.error('Error fetching LMS:', error);
    }
};


  async function handleDelete(orgId) {
    try {
      await del(`lms/${orgId}`);
      fetchOrganizations();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  }

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterOrganizations(e.target.value);
  };

  const filterOrganizations = (query) => {
    const filteredOrgs = organizations.filter(org => {
      // Check if all the necessary properties exist before filtering
      if (
        org.category &&
        org.category.industry &&
        org.course &&
        org.course.course_title &&
        org.lesson_title &&
        org.thumbnail_URL
      ) {
        return (
          org.category.industry.toLowerCase().includes(query.toLowerCase()) ||
          org.course.course_title.toLowerCase().includes(query.toLowerCase()) ||
          org.lesson_title.toLowerCase().includes(query.toLowerCase())
        );
      } else {
        // If any necessary property is missing, don't include this item in filtered results
        return false;
      }
    });
    setFilteredOrganizations(filteredOrgs);
    setCurrentPage(1);
  };
  

  const indexOfLastOrg = currentPage * organizationsPerPage;
  const indexOfFirstOrg = indexOfLastOrg - organizationsPerPage;
  const currentOrgs = filteredOrganizations.slice(indexOfFirstOrg, indexOfLastOrg);

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredOrganizations.length / organizationsPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredOrganizations.length / organizationsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <DashboardContent pageTitle="List of LMS">
      <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createLMS'} className="fw-btn-fill btn-gradient-yellow">Create LMS</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={formData.area} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Thumbnail</th>
                {/* <th>Name</th> */}
                <th>Title</th>

                <th>Course</th>
                <th>Category</th>
                {/* <th>Module</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedLMS">
            
              {/* Render LMS */}
              {currentOrgs.map((org, index) => (
                <tr key={index}>
                  <td><img alt='thumbnail' src={org.thumbnail_URL} width={100}></img></td>
                  {/* <td>{org.adminusername}</td> */}
                  <td>{org.lesson_title}</td>

                  <td>{org.course.course_title}</td>
                  <td>{org.category.industry}</td>
                  {/* <td>{org.module}</td> */}
                  <td><i onClick={()=>window.location='/editlms?id='+ org.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(org.id)} className='fa fa-trash'></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredOrganizations.length / organizationsPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredOrganizations.length / organizationsPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default ListOfLMS;
