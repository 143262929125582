import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { post } from "./rest";
import { ToastContainer, toast } from "react-toastify";
import QuillEditor from "./QuillEditor";
import Select from "react-select";
import QuizQuestionsModal from "./QuizQuestionsModal";

const SelectFieldQuiz = ({
  options,
  handleQuizSubmit,
  handleAssessmentChange,
  selectedOptionCourses,
  selectedOptionModule,
  selectedOptionUnit,
  selectedOptionLesson,
  selected
}) => {
  const [showModal, setShowModal] = useState(false);
  const [quizData, setQuizData] = useState({
    title: "",
    description: "",
    averagePassPercentage: "",
    difficultyLevel: "",
    timer: "",
    selectedAssessment: selected || null
  });

  useEffect(() => {
    setQuizData(prevState => ({
      ...prevState,
      selectedAssessment: selected || null
    }));
  }, [selected]);

  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [quizIdForQuestions, setQuizIdForQuestions] = useState(null);
  const [selectedQuizId, setSelectedQuizId] = useState(null);

  const handleQuizzSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await post("assessmentquiz", {
        ...quizData,
        course: selectedOptionCourses,
        coursemodule: selectedOptionModule,
        coursechapter: selectedOptionUnit,
        lms: selectedOptionLesson,
      });
  
      if (response.statusCode === 201) {
        debugger
        setSelectedQuizId(response.response.assessmentQuiz.id);
        handleQuizSubmit(selectedQuizId); // Notify parent component of quiz submission
        setQuizData({
          title: "",
          description: "",
          averagePassPercentage: "",
          difficultyLevel: "",
          timer: "",
          selectedAssessment: selected || null
        });
        debugger
        handleCloseModal();
        toast.success("Quiz created successfully!");

      } else {
        toast.error("Failed to create quiz");
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
      toast.error("Failed to create quiz");
    }
  };
  

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleViewQuestions = () => {
    if (quizData.selectedAssessment) {
      setQuizIdForQuestions(quizData.selectedAssessment.value);
      setShowQuestionsModal(true);
    }
  };

  const handleAssessmentSelectChange = (selectedOption) => {
    setQuizData({ ...quizData, selectedAssessment: selectedOption });
    handleAssessmentChange(selectedOption);
  };

  return (
    <>
      <Button onClick={handleShowModal} className="secondary-btn ms-4">Create Quiz</Button>
      <Form.Group controlId="formSelectedAssessment">
        <Form.Label>Select Assessment</Form.Label>
        <Select
          options={options}
          value={quizData.selectedAssessment}
          onChange={handleAssessmentSelectChange}
          placeholder="Select Assessment"
          isSearchable
          required
        />
      </Form.Group>
      {quizData.selectedAssessment && (
        <Button onClick={handleViewQuestions} className="mt-4 secondary-btn">View Questions</Button>
      )}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create New Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleQuizzSubmit}>
            <Form.Group className="form-group">
              <Form.Label>Quiz Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter quiz title"
                value={quizData.title}
                onChange={(e) =>
                  setQuizData({ ...quizData, title: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="formQuizDescription" className="form-group">
              <Form.Label>Quiz Description</Form.Label>
              <QuillEditor
                value={quizData.description}
                onChange={(content) =>
                  setQuizData({ ...quizData, description: content })
                }
                required
              />
            </Form.Group>
            <Row>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
              <Form.Group  className="form-group">
              <Form.Label>Average Pass Percentage</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter average pass percentage"
                value={quizData.averagePassPercentage}
                onChange={(e) =>
                  setQuizData({
                    ...quizData,
                    averagePassPercentage: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
              </Col>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
              <Form.Group className="form-group">
              <Form.Label>Difficulty Level</Form.Label>
              <Form.Control
                as="select"
                value={quizData.difficultyLevel}
                onChange={(e) =>
                  setQuizData({
                    ...quizData,
                    difficultyLevel: e.target.value,
                  })
                }
                required
              >
                <option value="">Select Difficulty Level</option>
                {[...Array(11).keys()].map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
              </Col>
              <Col md={4} xl={4} lg={4} xs={12} className="form-group">
              <Form.Group className="form-group">
              <Form.Label>Timer (in minutes)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter quiz timer"
                value={quizData.timer}
                onChange={(e) => setQuizData({ ...quizData, timer: e.target.value })}
                required
              />
            </Form.Group>
              </Col>
            </Row>
            
            
            
            <Button variant="primary" type="submit" className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
              Create Quiz
            </Button>
          </Form>
        </Modal.Body>
        <ToastContainer />

      </Modal>

      <QuizQuestionsModal
        show={showQuestionsModal}
        onHide={() => setShowQuestionsModal(false)}
        quizId={quizIdForQuestions}
        selectedQuizId={selectedQuizId}
        selectedOptionCourses={selectedOptionCourses}
        selectedOptionModule={selectedOptionModule}
        selectedOptionUnit={selectedOptionUnit}
        selectedOptionLesson={selectedOptionLesson}
      />

      <ToastContainer />
    </>
  );
};

export default SelectFieldQuiz;
