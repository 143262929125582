import React, { useState, useEffect } from 'react';
import DashboardContent from './DashboardContent';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Modules() {
    const [modules, setModules] = useState([]);
    const [filteredModules, setFilteredModules] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modulesPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
  
    useEffect(() => {
  
      fetchModules();
    }, []);
  
    const fetchModules = async () => {
      try {
        get('coursemodule')
          .then(response => {
            debugger
            var role = sessionStorage.getItem("roleslug");
          var organizationid = sessionStorage.getItem("organizationid");
          var moduledata ='';
          if (role === 'superadmin') {
            moduledata = response.response.filter(module => role !== "employee" && role !== "organizationadmin" && !module.organization );
          } else if (role === 'organizationadmin') {
            moduledata = response.response.filter(module => role !== 'superadmin' && !module.organization);
          } else {
            moduledata = response.response.filter(module => role !== 'superadmin' && !module.organization);
          }
            setModules(moduledata);
            setFilteredModules(moduledata);
          })
          .catch(error => console.error('Error fetching data:', error));          
    
      } catch (error) {
        console.error('Error fetching Modules:', error);
      }
    };
  
    const handleChange = (e) => {
      setSearchQuery(e.target.value);
      filterModules(e.target.value);
    };
  
    const filterModules = (query) => {
      const filteredMods = modules.filter(Mod =>
        Mod.title.toLowerCase().includes(query.toLowerCase())  ||
        Mod.course.course_title.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredModules(filteredMods);
      setCurrentPage(1);
    };
  
    const indexOfLastMod = currentPage * modulesPerPage;
    const indexOfFirstMod = indexOfLastMod - modulesPerPage;
    const currentMods = filteredModules.slice(indexOfFirstMod, indexOfLastMod);
    async function handleDelete(Id) {
      try {
        await del(`coursemodule/${Id}`);
        // Refresh Modules after deletion
        fetchModules();
      } catch (error) {
        console.error('Error deleting Modules:', error);
      }
    }
    const getPageNumbers = () => {
      const totalPages = Math.ceil(filteredModules.length / modulesPerPage);
      const pageNumbers = [];
      const maxVisiblePages = 5;
  
      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);
  
        if (startPage > 1) pageNumbers.push(1, '...');
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
        }
        if (endPage < totalPages) pageNumbers.push('...', totalPages);
      }
      return pageNumbers;
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < Math.ceil(filteredModules.length / modulesPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  return (
    <DashboardContent pageTitle="Modules">
  <div>
        {/* <Row className='text-right my-5'>
          <Col md={{span:3, offset:9}}>
          <Button type="button" onClick={()=>window.location='/createIndustry'} className="fw-btn-fill btn-gradient-yellow">Create Category</Button>
          </Col>
        </Row> */}

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" ">
          <Col md={6} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} onChange={handleChange} placeholder="Search ..." className="form-control"  />
          </Col>
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Module</th>
                <th>Course</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedModules">

              {/* Render Modules */}
              {currentMods.map((Mod, index) => (
                <tr key={index}>
                   <td>{Mod.title}</td>
                   <td>{Mod.course && Mod.course.course_title}</td>
                  <td><i onClick={()=>window.location='/editmodule?id='+ Mod.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(Mod.id)} className='fa fa-trash'></i></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredModules.length / modulesPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredModules.length / modulesPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Modules;
