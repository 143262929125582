import React, { useEffect, useState } from "react";
import DashboardContent from "./DashboardContent";
import { Button, Col, Form, Row } from "react-bootstrap";
import { get, post } from "./rest";
import { ToastContainer, toast } from "react-toastify";
import QuillEditor from "./QuillEditor";
import SelectField from "./SelectField";
import SelectFieldQuiz from "./SelectFieldQuiz";
import CustomSelectField from "./CustomSelectField";
import UnitSelectField from "./UnitSelectField";

function CreateAssessment() {
  const [formData, setFormData] = useState({
    question: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    correct_answer:"",
    coursemodule:"",
    coursechapter:"",
    lms:"",
    assessmentQuiz:""
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsCourses, setOptionsCourse] = useState([]);
  const [selectedOptionCourses, setSelectedOptionCourses] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsModuleCourse, setOptionsModuleCourse] = useState([]); 
  const [selectedOptionModule, setSelectedOptionModule] = useState(null); 
  const [optionsUnitModule, setOptionsUnitModule] = useState([]); 
  const [selectedOptionUnit, setSelectedOptionUnit] = useState(null);
  const [optionsLessonUnit, setOptionsLessonUnit] = useState([]);
  const [selectedOptionLesson, setSelectedOptionLesson] = useState(null); 
  const [existingAssessments, setExistingAssessments] = useState([]); 
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [newQuizFormData, setNewQuizFormData] = useState({
    title: '',
    description:''
  });
  const [newModuleFormData, setNewModuleFormData] = useState({
    title: '',
  });
  const [newUnitFormData, setNewUnitFormData] = useState({
    title: '',
  });
  const [newLessonFormData, setLessonFormData] = useState({
    lesson_title: '',
  });
  useEffect(() => {
    fetchCourseOptions();
    fetchExistingAssessments();
  }, []);
  const fetchExistingAssessments = async () => {
    try {
      const response = await get(`assessmentquiz`);
      // const response = await get(`assessmentquiz?course=${selectedOptionCourses}`);
      const assessments = response.response
        .filter((assessment) => !assessment.organization)
        .map((assessment) => ({
          value: assessment.id,
          label: assessment.title
        }));
      debugger;
      setExistingAssessments(assessments);
    } catch (error) {
      console.error('Error fetching existing assessments:', error);
    }
    
  };
  const fetchCourseOptions = async () => {
    try {
      const response = await get('courses');
      const options = response.response.filter(cours=> !cours.organization).map(course => ({
        value: course.id,
        label: course.course_title
      }));
      setOptionsCourse(options);
    } catch (error) {
      console.error('Error fetching Courses options:', error);
    }
  };
  const handleChangeCourses = (selectedOptionCourses) => {
    setSelectedOptionCourses(selectedOptionCourses);
    setFormData(prevState => ({
      ...prevState,
      course: selectedOptionCourses
    }));
    fetchCourseModuleOption(selectedOptionCourses); 
    setOptionsUnitModule([]);
    fetchExistingAssessments(selectedOptionCourses);
    debugger;
  };
  const fetchCourseModuleOption = async (selectedOptionCourses) => {
    try {
      const response = await get(`coursemodule?course=${selectedOptionCourses}`);
      const options = response.response
        .map((module) => {
          if (!module.organization) {
            return {
              value: module.id,
              label: module.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };

  // Handle module change
  const handleChangeModule = (selectedModule) => {
    // const selectedModule = event.target.value;

    setSelectedOptionModule(selectedModule);
    setFormData((prevState) => ({
      ...prevState,
      coursemodule: selectedModule,
    }));
    
    fetchCourseUnitOption(selectedModule)
  };
  const fetchCourseUnitOption = async (selectedOptionModule) => {
    
    try {
      const response = await get(`coursechapter?courseModule=${selectedOptionModule}`);
      debugger;
      const options = response.response
        .map((unit) => {
          if (!unit.organization) {
            // if (unit.organization === organizationid) {
            return {
              value: unit.id,
              label: unit.title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitModule(options);
    } catch (error) {
      console.error("Error fetching Unit options:", error);
    }
  };
  // Handle unit change
  const handleChangeUnit = (selectedUnit) => {
    // const selectedUnit = event.target.value;

    setSelectedOptionUnit(selectedUnit);
    setFormData((prevState) => ({
      ...prevState,
      coursechapter: selectedUnit,
    }));
    fetchCourseLessonOption(selectedUnit);
  };
  const fetchCourseLessonOption = async (selectedOptionUnit) => {
    try {
      const response = await get(`lms?coursechapter=${selectedOptionUnit}`);
      const options = response.response.filter(lms => lms.coursechapter && lms.coursechapter === selectedOptionUnit)
        .map((lesson) => {
          debugger;
          if (!lesson.organization) {
            return {
              value: lesson.id,
              label: lesson.lesson_title,
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsLessonUnit(options);
    } catch (error) {
      console.error("Error fetching Module options:", error);
    }
  };
  const handleChangeLesson = (selectedLesson) => {
    debugger;
    // const selectedLesson = event.target.value;

    setSelectedOptionLesson(selectedLesson);
    setFormData((prevState) => ({
      ...prevState,
      lms: selectedLesson,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditorChange = (name, content) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: content,
    }));
  };
  const handleAssessmentChange = (selectedAssessment, event) => {
    debugger;
    if (event) {
        event.stopPropagation(); // Stop event propagation
    }
    setSelectedAssessment(selectedAssessment.value);
    setFormData((prevState) => ({
        ...prevState,
        assessmentQuiz: selectedAssessment.value,
    }));
  };

  const fetchCourseModuleOptionfilter = async (courseId, filter) => {
    try {
      const response = await get(`coursemodule?course=${courseId}`);
      const options = response.response
        .map((module) => {
          if (!module.organization) {
            return {
              value: module.id,
              label: module.title,
              isselected: module.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsModuleCourse(options);
      const selectedModule = options.find(module => module.label === filter || module.value === filter);
  
      if (selectedModule) {
        debugger;
        setSelectedOptionModule(selectedModule.value); 
        setFormData(prevState => ({
          ...prevState,
          coursemodule: selectedModule.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Module options:', error);
    }
  };
  
  const fetchCourseUnitOptionfilter = async (moduleId, filter) => {
    try {
      const response = await get(`coursechapter?courseModule=${moduleId}`);
      const options = response.response
        .map((unit) => {
          if (!unit.organization) {
            return {
              value: unit.id,
              label: unit.title,
              isselected: unit.title === filter
            };
          }
          return null;
        })
        .filter(Boolean);
      setOptionsUnitModule(options);
      
      const selectedUnit = options.find(Unit => Unit.label === filter || Unit.value === filter);
  
      if (selectedUnit) {
        setSelectedOptionUnit(selectedUnit.value); 
        setFormData(prevState => ({
          ...prevState,
          coursechapter: selectedUnit.value
        }));
      }
    } catch (error) {
      console.error('Error fetching Unit options:', error);
    }
  };
  
  const handleModuleSubmit = async (e, filter) => {
    e.preventDefault();
    // const courseId = selectedOptionCourses?.value;
    if (!selectedOptionCourses) {
      toast.error('Please select a course before creating a module');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursemodule', { title: filter, course: selectedOptionCourses });
      if (response.statusCode === 201) {
        
        toast.success('New module added successfully', {
          onClose: () => {
            fetchCourseModuleOptionfilter(selectedOptionCourses, filter);

            setNewModuleFormData({
              title: '',
            });
          setSelectedOptionUnit(null);
handleChangeUnit(selectedOptionUnit);

          }
        });
        debugger;
        
      } else {
        toast.error('Failed to add new module');
      }
    } catch (error) {
      console.error('Error adding new module:', error);
      toast.error('Failed to add new module');
    }
  };
  const handleUnitSubmit = async (e, filter) => {
    e.preventDefault();
    // const moduleId = selectedOptionModule?.value;
    if (!selectedOptionModule) {
      toast.error('Please select a Module before creating a Unit');
      return;
    }
    debugger;
    try {
      debugger;
      const response = await post('/coursechapter', { title: filter, coursemodule: selectedOptionModule  });
      if (response.statusCode === 201) {
        toast.success('New Unit added successfully',{
          onClose: () => {
            setNewUnitFormData({
              title: '',
            });
            fetchCourseUnitOptionfilter(selectedOptionModule, filter);
          }
        });
 
        
      } else {
        toast.error('Failed to add new Unit');
      }
    } catch (error) {
      console.error('Error adding new Unit:', error);
      toast.error('Failed to add new Unit');
    }
  };
  const handleQuizSubmit = () => {
    debugger;
    fetchExistingAssessments(selectedOptionCourses);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const roleid = sessionStorage.getItem("roleid");
    const roleslug = sessionStorage.getItem("roleslug");
    formData.type = 'multipleChoice';
    const organizationid = sessionStorage.getItem("organizationid");
    var CourseCluster = '';
    var assessmentCluster='';
    if (roleslug === 'superadmin'){
      CourseCluster = '65eef8496cbe0baacc3aab6f';
      assessmentCluster ='6607a835f89bfe1c7d5f0807';
    } else {
      CourseCluster = '65eef8586cbe0baacc3aae21';
      assessmentCluster ='6607a83df89bfe1c7d5f0809';
      formData.organization = organizationid;
    }
      const formDataWithAnswer = {
        ...formData,
        correct_answer: selectedOption,
        assessmentCluster: assessmentCluster,
        courseCluster: CourseCluster,
        role:roleid,
        createdBy: sessionStorage.getItem("authorid"), 
      };
      debugger;
      const responseData = await post("assessment", formDataWithAnswer);
      if (responseData.statusCode === 201) {
        toast.success("Created Successfully", {
          onClose: () => {
            // window.location.href = "/questionbank";
            setFormData((prevState) => ({
              ...prevState,
              question: '',
              difficultyLevel: '',
              correct_answer:'',
              answer1:'',
              answer2:'',
              answer3:'',
              answer4:'',
            }));
            setSelectedOption([]);
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  

  return (
    <DashboardContent pageTitle="Create Question - Multiple Choice">
      <Row>
        <Col md={12}>
              
              <div className="student-info hidecheckox">
                <Form onSubmit={handleSubmit} id="createQuestion" className="mt-5">
                  <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                      <Form.Label>Course:</Form.Label>
                      <SelectField options={optionsCourses} placeholder="Select Course" onChange={handleChangeCourses} />
                    </Col>
                    </Row>
                    <Row>
                    {selectedOptionCourses && ( // Render module select box only if course is selected
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                        {/* <Form.Label>Module:</Form.Label> */}
                        <CustomSelectField options={optionsModuleCourse} placeholder="Select Module" onChange={handleChangeModule} isSearchable selected={formData.coursemodule} handleModuleSubmit={handleModuleSubmit}/>

                    </Col>
                     )}  
                     {selectedOptionModule && ( // Render unit select box only if module is selected */} */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    {/* <Form.Label>Unit:</Form.Label> */}
                    {/* <SelectField options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} /> */}
                    <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} isSearchable selected={formData.coursechapter} handleUnitSubmit={handleUnitSubmit}/>
                  </Col>
                 )}  
                 {selectedOptionUnit && ( // Render unit select box only if module is selected */} */}
                  <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                    <Form.Label>Lesson:</Form.Label>
                    <SelectField options={optionsLessonUnit} placeholder="Select Lesson" onChange={handleChangeLesson} />
                    {/* <UnitSelectField  options={optionsUnitModule} placeholder="Select Unit" onChange={handleChangeUnit} selected={formData.coursechapter} isSearchable handleUnitSubmit={handleUnitSubmit}/> */}
                  </Col>
                 )} 
                 <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                 <Form.Label>Assessment Title:</Form.Label>
              
                     <SelectFieldQuiz
                        options={existingAssessments}
                        value={selectedAssessment}
                        selectedAssessment={selectedAssessment}
                        handleAssessmentChange={handleAssessmentChange}
                        isSearchable
                        selectedOptionCourses={selectedOptionCourses}
                        selectedOptionModule={selectedOptionModule}
                        selectedOptionUnit={selectedOptionUnit}
                        selectedOptionLesson={selectedOptionLesson}
                        handleQuizSubmit={handleQuizSubmit}
                        placeholder="Select a Quiz"
                        />
                 </Col>
                    <Col md={12} className="form-group">
                      <Form.Group controlId="question">
                        <Form.Label>Question</Form.Label>
                        <QuillEditor
                          name="question"
                          value={formData.question}
                          onChange={(content) =>
                            handleEditorChange("question", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="answer1">
                        <Form.Label>Answer 1</Form.Label>
                        <QuillEditor
                          name="answer1"
                          value={formData.answer1}
                          onChange={(content) =>
                            handleEditorChange("answer1", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="answer2">
                        <Form.Label>Answer 2</Form.Label>
                        <QuillEditor
                          name="answer2"
                          value={formData.answer2}
                          onChange={(content) =>
                            handleEditorChange("answer2", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="answer3">
                        <Form.Label>Answer 3</Form.Label>
                        <QuillEditor
                          name="answer3"
                          value={formData.answer3}
                          onChange={(content) =>
                            handleEditorChange("answer3", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Form.Group controlId="answer4">
                        <Form.Label>Answer 4</Form.Label>
                        <QuillEditor
                          name="answer4"
                          value={formData.answer4}
                          onChange={(content) =>
                            handleEditorChange("answer4", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} xl={6} lg={6} xs={12} className="mt-4">
                      <Form.Label>Correct Answer</Form.Label>
                      <Form.Group className="mt-3">
                        <Form.Check
                          type="radio"
                          label="Answer 1"
                          name="Answers"
                          id="Answer1"
                          checked={selectedOption === "Answer1"}
                          onChange={() => setSelectedOption("Answer1")}
                        />
                        <Form.Check
                          type="radio"
                          label="Answer 2"
                          name="Answers"
                          id="Answer2"
                          checked={selectedOption === "Answer2"}
                          onChange={() => setSelectedOption("Answer2")}
                        />
                        <Form.Check
                          type="radio"
                          label="Answer 3"
                          name="Answers"
                          id="Answer3"
                          checked={selectedOption === "Answer3"}
                          onChange={() => setSelectedOption("Answer3")}
                        />
                        <Form.Check
                          type="radio"
                          label="Answer 4"
                          name="Answers"
                          id="Answer4"
                          checked={selectedOption === "Answer4"}
                          onChange={() => setSelectedOption("Answer4")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Group className="form-group">
                  <Form.Label>Difficulty Level</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.difficultyLevel}
                    onChange={(e) => setFormData({ ...formData, difficultyLevel: e.target.value })}
                    required
                  >
                    <option value="">Select Difficulty Level</option>
                    {[...Array(11).keys()].map((level) => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                </Col>
                <Col md={12} xl={12} lg={12} xs={12} className="form-group">
                      <Form.Group controlId="explanation">
                        <Form.Label>Explanation</Form.Label>
                        <QuillEditor
                          name="explanation"
                          value={formData.explanation}
                          onChange={(content) =>
                            handleEditorChange("explanation", content)
                          }
                        />
                      </Form.Group>
                    </Col>
                 </Row>
                 <Row>
                    <Col md={6} xl={6} lg={6} xs={12} className="form-group">
                      <Button
                        type="submit"
                        className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark" >
                        Create Question
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
        </Col>
      </Row>
      <ToastContainer />
    </DashboardContent>
  );
}

export default CreateAssessment;
