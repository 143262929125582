import React from "react";
import DashboardSuperAdmin from "./DashboardSuperAdmin";
import DashboardOrganizationAdmin from "./DashboardOrganizationAdmin";

function Dashboard() {
  var role = sessionStorage.getItem("roleslug");
  var dashboardComponent = null;

  if (role === 'superadmin') {
    dashboardComponent = <DashboardSuperAdmin />;
  } else if (role === 'organizationadmin') {
    dashboardComponent = <DashboardOrganizationAdmin />;
  } else {
    dashboardComponent = <DashboardOrganizationAdmin />
  }

  return (
    <>
      {dashboardComponent}
    </>
  );
}

export default Dashboard;
