import React, { useState } from 'react';
import DashboardContent from './DashboardContent';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

function CreateChapter() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle video upload logic here
    if (selectedVideo) {
      // You can perform upload operations here, such as sending the video to a server
      console.log('Selected video:', selectedVideo);
    } else {
      console.log('No video selected.');
    }
  };
  const handleChange = () =>{

  }
  const handleVideoInput = (event) => {
    setSelectedVideo(event.target.files[0]);
  };
  return (
    <DashboardContent pageTitle="Chapter">
    <Row>
    <Col md={12}>
        <div className="card dashboard-card-ten">
          <div className="card-body">
            <div className="heading-layout1">
              <div className="item-title">
                <h3>Create Chapter</h3>
              </div>
            </div>
            <div className="student-info">
              <Form onSubmit={handleSubmit} id="createChapter" className="mt-5">
              <Row>
                  <Col md={12} className="form-group">
                    <Form.Label>Chapter Title:</Form.Label>
                    <Form.Control type="text" id="Chaptertitle" name="Chaptertitle" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col md={12} className="form-group">
                    <Form.Label>Meta Title:</Form.Label>
                    <Form.Control type="text" id="Chaptermetatitle" name="Chaptermetatitle" className="form-control" onChange={handleChange} required />
                  </Col>
                  <Col xs={12} className="form-group">
                    <Form.Label>Meta Description:</Form.Label>
                    <textarea id="Chaptermetadescr" name="Chaptermetadescr" className="form-control" onChange={handleChange} required></textarea>
                  </Col>                 
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                  <Form.Group controlId="videoUpload">
              <Form.Label>Upload Video:</Form.Label>
              {/* <Form.File 
                accept="video/*"
                onChange={handleVideoInput}
                label={selectedVideo ? selectedVideo.name : "Choose File"} 
              /> */}
            <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" required />

            </Form.Group>
                  </Col>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Form.Label>Thumbnail:</Form.Label>
                    <div className="wrapper">
                      <div className="box">
                        <div className="js--image-preview"></div>
                        <div className="upload-options">
                            <Form.Control type="file" id="imgfile" className="image-upload" accept="image/*" required />
                        </div>
                      </div>
                    </div>
                  </Col>
                  </Row>
                    <Row>
                  <Col md={4} xl={4} lg={4} xs={12} className="form-group">
                    <Button type="submit"  className="mt-5 btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Create Chapter</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <ToastContainer />
    </DashboardContent>
  );
}

export default CreateChapter;
