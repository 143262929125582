import React, { useState, useEffect } from 'react';
import DashboardContent from "./DashboardContent";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { del, get } from './rest';

function Employees() {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeesPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {

    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      get('users')
        .then(response => {
          const employeesData = response.response.filter(emp => emp.role.slug === 'employee');
          debugger

        setEmployees(employeesData);
        setFilteredEmployees(employeesData);
        })
        .catch(error => console.error('Error fetching data:', error));          
  
    } catch (error) {
      console.error('Error fetching Employees:', error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    filterEmployees(e.target.value);
  };

  const filterEmployees = (query) => {
    const filteredEmps = employees.filter(OrgAdmin =>
        // Filter based on the search query
        { if (
          OrgAdmin.username &&
          OrgAdmin.firstname &&
          OrgAdmin.lastname &&
          OrgAdmin.email &&
          OrgAdmin.role &&
          OrgAdmin.role.role &&
          OrgAdmin.state &&
          OrgAdmin.city &&
          OrgAdmin.phoneNumber
        ) {
          return (
            OrgAdmin.username.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.firstname.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.lastname.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.email.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.role.role.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.state.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.city.toLowerCase().includes(query.toLowerCase()) ||
            OrgAdmin.phoneNumber.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          // If any necessary property is missing, don't include this item in filtered results
          return false;
        }}
    );
    setFilteredEmployees(filteredEmps);
    setCurrentPage(1);
};  

  const indexOfLastEmp = currentPage * employeesPerPage;
  const indexOfFirstEmp = indexOfLastEmp - employeesPerPage;
  const currentEmps = filteredEmployees.slice(indexOfFirstEmp, indexOfLastEmp);
  async function handleDelete(Id) {
    try {
      await del(`users/${Id}`);
      // Refresh industries after deletion
      fetchEmployees();
    } catch (error) {
      console.error('Error deleting industries:', error);
    }
  }
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pageNumbers.push(1, '...');
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages) pageNumbers.push('...', totalPages);
    }
    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredEmployees.length / employeesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <DashboardContent pageTitle="List of Employees">
      <div>
        <Row className='text-right my-5'>
        <Col md={4} className="form-group">
            <Form.Control type="text" name="name" value={searchQuery} placeholder="Search ..." className="form-control" onChange={handleChange} />
          </Col>
          <Col md={{span:3, offset:5}}>
          <Button type="button" onClick={()=>window.location='/createemployee'} className="fw-btn-fill btn-gradient-yellow">Create Employee</Button>
          </Col>
        </Row>

      {/* <Form onSubmit={handleSubmit} className="mg-b-20"> */}
        <Row className=" gutters-8">
          
          {/* <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="area" value={formData.area} onChange={handleChange} placeholder="SearchArea ..." className="form-control" onChange={handleChange} />
          </div>
          <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
            <Form.Control type="text" name="username" value={formData.username} onChange={handleChange} placeholder="SearchUsername ..." className="form-control" onChange={handleChange} />
          </div> */}
          {/* <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
            <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
          </div> */}
        </Row>
      {/* </Form> */}
        <div className="table-responsive" style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table className="table display data-table" striped hover responsive>
            <thead>
              <tr>
                 {/* <th> */}
                {/*  <div className="form-check">
                    <Form.Control type="checkbox" className="form-check-input checkAll" /> 
                    <Form.Label className="form-check-label">Logo</Form.Label>
                  </div>*/}
                {/* </th>  */}
                <th>Username</th>
                <th>First name</th>
                <th>Last name</th>
                <th>E-mail</th>
                <th>Phone</th>
                <th>Role</th>
                <th>State</th>
                <th>City</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="requestedEmployees">
           
            {currentEmps
        .filter(emp => emp.role.slug === 'employee').map((emp, index) => (
                <tr key={index}>
                  <td>{emp.username}</td>
                  <td>{emp.firstname}</td>
                  <td>{emp.lastname}</td>
                  <td>{emp.email}</td>
                  <td>{emp.phoneNumber}</td>
                  <td>{emp.role.role}</td>
                  <td>{emp.state}</td>
                  <td>{emp.city}</td>
                  <td><i onClick={()=>window.location='/editemployee?id='+ emp.id} className='fa fa-edit' style={{ paddingRight: '10px' }}></i><i onClick={()=>handleDelete(emp.id)} className='fa fa-trash'></i></td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Pagination */}
        <nav className='d-flex justify-content-end my-5 align-items-center'>
        <Button
            className={`btn plain-btn ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <ul className="pagination">
            {getPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === pageNumber ? 'active' : ''} ${pageNumber === '...' ? 'disabled' : ''}`}
              >
                {pageNumber === '...' ? (
                  <span className="page-link">...</span>
                ) : (
                  <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                )}
              </li>
            ))}
          </ul>
          <Button
            className={`btn plain-btn ${currentPage === Math.ceil(filteredEmployees.length / employeesPerPage) ? 'disabled' : ''}`}
            onClick={handleNext}
            disabled={currentPage === Math.ceil(filteredEmployees.length / employeesPerPage)}
          >
            Next
          </Button>
        </nav>
      </div>
    </DashboardContent>
  );
}

export default Employees;
