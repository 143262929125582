import { Button, Card, Col, Container, Row } from "react-bootstrap";
import DashboardContent from "./DashboardContent";
import { useEffect, useState } from "react";
import { get } from "./rest";

function DashboardSuperAdmin() {
  const [course, setCoursesCount] = useState();
  const [enquiries, setEnquiriesCount] = useState();
  const [organizationList, setOrganizationList] = useState([]);
  const [org, setOrgCount] = useState();

  useEffect(() => {
    fetchOrgCount();
    fetchOrganizations();
  }, []);

  const fetchOrgCount = async () => {
    try {
      const response = await get('/organization/count');
      setOrgCount(response.response);
    } catch (error) {
      console.error('Error fetching organization count:', error);
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await get('organization');
      const filteredOrganizations = response.response;
      setOrganizationList(filteredOrganizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  return (
    <DashboardContent pageTitle="Dashboard">
      <Container>
        <Row>
          <Col md={6}>
            <div className="dashboard-summery-one mg-b-20">
              <Row className="align-items-center">
                <Col md={4}>
                  <div className="item-icon bg-light-green">
                    <i className="fa fa-landmark text-green"></i>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="item-content">
                    <div className="item-title">No. of Organizations</div>
                    <div className="item-number" id="ClassCount">
                      {org}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <h2 className="my-5">Organizations Details</h2>
        <Row>
          {organizationList.map((org, index) => (
            <Col key={index} md={6} className="mb-3">
              <Card>
                <Card.Body>
                  <h5>{org.adminOrganization}</h5>
                  <Row className="px-4">
                    <Col md={6} className="mt-4 px-2">
                    <Card className="p-4">
                      <p className="m-0">Total Users - 
                        <AsyncRenderUserCount orgId={org.id} />
                      </p>
                    </Card>
                    </Col>
                    <Col md={6} className="mt-4 px-2">
                      <Card className="p-4">
                        <p className="m-0">Total Courses - 
                          <AsyncRenderCoursesCount orgId={org.id} />
                        </p>
                      </Card>
                    </Col>
                    <Col md={6} className="mt-4 px-2">
                      <Card className="p-4">
                      <p className="m-0">Total Lessons - 
                        <AsyncRenderLessonCount orgId={org.id} />
                      </p>
                      </Card>
                    </Col>
                    <Col md={6} className="mt-4 px-2">
                      <Card className="p-4">
                      <p className="m-0">Total Quizes - 
                        <AsyncRenderQuizCount orgId={org.id} />
                      </p>
                      </Card>
                    </Col>
                  </Row>
                  
                  
                 
                 
                  
                  {/* <p>From - {org.adminemail}</p> */}
                  {/* <Card.Subtitle className="mb-2 text-muted">Time - {org.updatedAt ? org.updatedAt : org.createdAt}</Card.Subtitle> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </DashboardContent>
  );
}

const AsyncRenderUserCount = ({ orgId }) => {
  const [userCount, setUserCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await get('users?organization=' + orgId);
        setUserCount(response.response.length);
      } catch (error) {
        console.error('Error fetching user count:', error);
        setUserCount(0);
      }
    };

    fetchUserCount();
  }, [orgId]);

  return <>{userCount !== null ? userCount : 'Loading...'}</>;
};
const AsyncRenderCoursesCount = ({ orgId }) => {
  const [courseCount, setCourseCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await get('courses?organization=' + orgId);
        setCourseCount(response.response.length);
      } catch (error) {
        console.error('Error fetching user count:', error);
        setCourseCount(0);
      }
    };

    fetchUserCount();
  }, [orgId]);

  return <>{courseCount !== null ? courseCount : 'Loading...'}</>;
};
const AsyncRenderLessonCount = ({ orgId }) => {
  const [lessonCount, setLessonCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await get('lma?organization=' + orgId);
        setLessonCount(response.response.length);
      } catch (error) {
        console.error('Error fetching user count:', error);
        setLessonCount(0);
      }
    };

    fetchUserCount();
  }, [orgId]);

  return <>{lessonCount !== null ? lessonCount : 'Loading...'}</>;
};
const AsyncRenderQuizCount = ({ orgId }) => {
  const [quizCount, setQuizCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await get('assessmentquiz?organization=' + orgId);
        setQuizCount(response.response.length);
      } catch (error) {
        console.error('Error fetching user count:', error);
        setQuizCount(0);
      }
    };

    fetchUserCount();
  }, [orgId]);

  return <>{quizCount !== null ? quizCount : 'Loading...'}</>;
};

export default DashboardSuperAdmin;
